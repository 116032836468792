import { Button } from "@chakra-ui/button";
import { Badge, HStack, SimpleGrid, Text } from "@chakra-ui/layout";
import { Box, VStack } from "@chakra-ui/layout";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { ShowError } from "../../../generic/ShowError";
import { EventItemType } from "../../../global/types";
import { useBreakpointValue } from "@chakra-ui/react";
import { EventSummary } from "./EventSummary";
import { GetDaysLeft } from "./helper/GetDaysLeft";
import { t } from "i18next";
import { ShowWarning } from "../../../generic/ShowWarning";
import { StatBadge, StatItem } from "./StatItem";
import { UpDownIcon, ViewIcon } from "@chakra-ui/icons";
import { SubmitToEvent } from "./SubmitToEvent";
import "./eventDetails.css";

export function EventDetails() {
  let { eventId } = useParams<{ eventId: string }>();

  return (
    <>
      {eventId ? (
        <EventDetailsHandler eventId={eventId} mode="register" />
      ) : (
        <>...</>
      )}
    </>
  );
}
export function EventDetailsHandler({
  eventId,
  mode,
  rightElement,
}: {
  eventId: string;
  mode: "cancel" | "register";
  rightElement?: JSX.Element | undefined;
}) {
  const { languageCode } = useParams();

  let url =
    "https://prod-07.germanywestcentral.logic.azure.com:443/workflows/2f4afe1f667c4c4b9d2c1a365c5f58aa/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=oksnDHERVBF64WLtUKqiCwKT-khpwIqfVr28SIA4A-E";
  const { data, status, isSuccess, refetch, error } = useQuery<EventItemType>(
    [],
    async () => {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ id: eventId }),
      };
      const res = await fetch(url, requestOptions);
      if (res.status >= 400) {
        throw new Error(res.statusText);
      } else {
        return res.json();
      }
    },
    {
      refetchOnWindowFocus: false,
      retry: false,

      //enabled: false, // turned off by default, manual refetch is needed
    }
  );
  return (
    <>
      {/* <BreadcrumbMenu secondLevel="events" /> 
      <Box h={10} />*/}
      <EventSummary eventItem={data} loading={status == "loading"} />{" "}
      {data && GetDaysLeft(data?.RegistrationDeadline) == 0 ? (
        <Box mt={6}>
          <ShowWarning message={t("event-registration-deadline-reached")} />
        </Box>
      ) : (
        data &&
        data?.BookingIsClosed && (
          <Box mt={6}>
            <ShowWarning message={t("event-registration-overbooked")} />
          </Box>
        )
      )}
      <Box h={5} />
      <SimpleGrid
        templateColumns={{ base: "1fr", sm: "1fr", md: "1fr 1fr" }}
        flexDirection="column"
        spacing={{ base: 10, md: 20 }}
        justifyContent="space-between"
      >
        <VStack spacing={2} alignItems="flex-start" flexWrap="wrap">
          <StatBadge label={t("event-introText").toString()} />
          <EventIntroText
            introText={
              languageCode == "en" && data?.IntroTextEn
                ? data?.IntroTextEn
                : data?.IntroTextDe
            }
          />
        </VStack>
        <Box
          display="flex"
          justifyContent={{ base: "flex-start", md: "flex-end" }}
        >
          <Box>
            {status == "error" && (
              <ShowError errorMessage={JSON.stringify(error)} />
            )}
            {mode == "register" && (
              <SubmitToEvent eventItem={data} loading={status == "loading"} />
            )}
            {rightElement}
          </Box>
        </Box>
      </SimpleGrid>
    </>
  );
}

export function EventIntroText({
  introText,
}: {
  introText: string | undefined;
}) {
  const variant = useBreakpointValue({
    base: 10,
    md: undefined,
  });
  const [showAllLines, setShowAllLines] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      {introText && (
        <Text
          /* whiteSpace="pre-wrap" */
          noOfLines={showAllLines ? undefined : variant}
          dangerouslySetInnerHTML={{ __html: introText }}
        ></Text>
      )}
      {showAllLines == false && (
        <Button
          onClick={() => setShowAllLines(true)}
          variant="link"
          mt={2}
          colorScheme="black"
          rightIcon={<UpDownIcon />}
          fontSize={14}
          visibility={{ base: "visible", md: "hidden" }}
        >
          {t("show-all-lines")}
        </Button>
      )}
    </>
  );
}
