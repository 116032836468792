import { Employee } from "../employee-list";
import React from "react";
import imgGretaNeubauKonradies from ".././media/greta-neubauer-konradies.png";

export const gretaNeubauerKonradies: Employee = {
  slug: "greta-neubauer-konradies",
  displayName: "Greta Neubauer-Konradies",
  title: "lawyer-female",
  isExternal: true,
  /* subTitle: "associate-female", */
  img: imgGretaNeubauKonradies,
  languages: ["deu", "eng", "esp"],
};
