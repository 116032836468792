import { StatLabel, StatNumber, StatHelpText } from "@chakra-ui/stat";
import { Badge, Box, VStack } from "@chakra-ui/react";
import React from "react";

export function StatItem({
  label,
  text,
  subText,
}: {
  label: string | JSX.Element | undefined;
  text: string | JSX.Element | undefined;
  subText?: string | JSX.Element | undefined;
}) {
  return (
    <VStack alignItems="start" flexWrap="wrap">
      <StatBadge label={label} />

      <Box
        lineHeight={"1.1em"}
        fontWeight={400}
        fontSize={{ base: "16px", md: "20px" }}
      >
        {text}
      </Box>

      {subText && (
        <Box fontSize={{ base: "14px", md: "16px" }} color="gray.800">
          {subText}
        </Box>
      )}
    </VStack>
  );
}

export function StatBadge({
  label,
}: {
  label: string | JSX.Element | undefined;
}) {
  return (
    <Badge fontSize={{ base: "16px", md: "20px" }} colorScheme="blue">
      {label}
    </Badge>
  );
}
