import { Employee } from "../employee-list";
import React from "react";
import imgOlgaSchaefer from ".././media/olga-schaefer.png";

export const olgaSchaefer: Employee = {
  slug: "olga-schaefer",
  displayName: "Olga Schäfer",
  title: "legal-assistant-female",
  languages: ["deu", "eng", "rus"],
  isExternal: false,
  hasProfile: false,
  img: imgOlgaSchaefer,
  translations: [],
};
