import { Badge, Box } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import i18n from "../../generic/i18n";
import { OpenPosition } from "./openPositionType";

export function OpenPositionCount() {
  const { languageCode } = useParams();
  const [openPositionCount, setOpenPositionCount] = useState(0);

  useEffect(() => {
    if (languageCode) {
      let openPositions: OpenPosition[] = i18n.t(
        "website-content-career.openPositions",
        {
          returnObjects: true,
          lng: languageCode,
        }
      );
      let count = openPositions.filter((x) => x.hasDetails).length;

      if (count > 0) {
        setOpenPositionCount(count);
      }
    }
  }, [languageCode]);

  return (
    <>
      {openPositionCount > 0 && (
        <Box>
          <Badge colorScheme="orange">{openPositionCount}</Badge>
        </Box>
      )}
    </>
  );
}
