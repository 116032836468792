import { Box, Divider } from "@chakra-ui/layout";
import { SimpleGrid, VStack, Text, Image } from "@chakra-ui/react";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  convertStringToWebsiteLang,
  fallbackLng,
  TranslatedInfo,
  websiteLanguages,
} from "../../generic/i18n";
import { BannerWithHeading } from "../../generic/layout/bannerWithHeading";
import { MaxWidthContainer } from "../../generic/layout/maxWidthContainer";
import { companyColors } from "../../global/companyColors";
import {
  Employee,
  employeeList,
  EmployeeTranslatedInfo,
} from "./employee-list";
import i18n from "../../generic/i18n";
import { HeadingMedium } from "../../generic/layout/headingMedium";

export function EmployeeDetails() {
  const { employeeSlug, languageCode } = useParams();
  const [employee, setEmployee] = useState<Employee | undefined>(undefined);
  const [employeeTranslatedInfo, setEmployeeTranslatedInfo] = useState<
    (EmployeeTranslatedInfo & TranslatedInfo) | undefined
  >(undefined);

  useEffect(() => {
    if (employeeSlug) {
      let employee = GetEmployeeDetails(employeeSlug);
      setEmployee(employee);
      let employeeTranslatedInfo: EmployeeTranslatedInfo & TranslatedInfo =
        i18n.t(employeeSlug, {
          returnObjects: true,
          lng: languageCode,
          ns: "contentTeam",
        });
      setEmployeeTranslatedInfo(employeeTranslatedInfo);
    }
  }, [employee, languageCode]);

  return (
    <VStack spacing={0} mb={8}>
      <BannerWithHeading bgUrl="/media/bg2.jpg" headingText={t("team")} />
      <MaxWidthContainer>
        {employee && (
          <Box mt={8}>
            <SimpleGrid
              templateColumns={{ base: "1fr", md: "220px 1fr" }}
              flexDirection="column"
              spacing={8}
              justifyContent="space-between"
              mb={4}
            >
              <Image src={employee.img} w={220} rounded={4} />
              <VStack flexWrap="wrap" alignItems="flex-start">
                <HeadingMedium mb={0}>
                  {employee.displayName}
                  {employee.displayNameSuffix &&
                    ", " + employee.displayNameSuffix}
                </HeadingMedium>
                <Text>
                  {t(employee.title)}
                  {employee.isExternal && <span>, {t("of-counsel")}</span>}
                  {employee.subTitle && <span>, {t(employee.subTitle)}</span>}
                </Text>
                {employee.secondTitle && <Text>{employee.secondTitle}</Text>}
              </VStack>
            </SimpleGrid>
            <Text mt={6} key={languageCode}>
              <Trans
                i18nKey={employee.slug + ".intro"}
                ns={"contentTeam"}
              ></Trans>
            </Text>

            <TextHeading text={"languages"} />
            <Text>
              {employee.displayName} {t("speaks")}{" "}
              {employee.languages.map((lang, i) => (
                <span key={i}>
                  {i + 1 < employee.languages.length ? "" : t("and") + " "}
                  {t("language.iso639." + lang)}
                  {i + 2 < employee.languages.length ? ", " : ""}
                  {i + 1 == employee.languages.length ? "." : " "}
                </span>
              ))}
            </Text>
            {employeeTranslatedInfo?.awards &&
              employeeTranslatedInfo?.awards.length > 0 && (
                <React.Fragment>
                  <TextHeading text={"awards"} />

                  <Text>
                    {employeeTranslatedInfo?.awards.length > 1 ? (
                      <ul>
                        {employeeTranslatedInfo?.awards?.map((award, i) => (
                          <li key={i}>{award}</li>
                        ))}
                      </ul>
                    ) : (
                      <span>{employeeTranslatedInfo?.awards[0]}</span>
                    )}
                  </Text>
                </React.Fragment>
              )}
            {employeeTranslatedInfo?.memberships &&
              employeeTranslatedInfo?.memberships.length > 0 && (
                <React.Fragment>
                  <TextHeading text={"memberships"} />
                  <Text>
                    {employeeTranslatedInfo?.memberships?.length == 1 ? (
                      <>{employeeTranslatedInfo?.memberships[0]} </>
                    ) : (
                      <ul>
                        {employeeTranslatedInfo?.memberships?.map(
                          (membership) => (
                            <li>{membership}</li>
                          )
                        )}
                      </ul>
                    )}
                  </Text>
                </React.Fragment>
              )}
          </Box>
        )}
      </MaxWidthContainer>
    </VStack>
  );
}

export function TextHeading({
  text,
  mt,
}: {
  text: string;
  mt?: undefined | number;
}) {
  const { t } = useTranslation();

  return (
    <Text
      fontSize={18}
      fontWeight={500}
      mt={mt || 10}
      bg={companyColors.blue[200]}
      p={2}
      rounded={4}
      mb={2}
    >
      {t(text)}
    </Text>
  );
}

function GetEmployeeDetails(slug: string): Employee {
  return employeeList.filter((x) => x.slug == slug)[0];
}

function GetEmployeeTranslatedInfo(
  exmployee: Employee,
  languageCode?: websiteLanguages | undefined
): (EmployeeTranslatedInfo & TranslatedInfo) | undefined {
  if (exmployee.translations && exmployee.translations?.length > 0) {
    let langToFind = languageCode || fallbackLng();
    let translatedInfo = exmployee.translations.find(
      (item) => item.languageCode === langToFind
    );
    if (translatedInfo) {
      return translatedInfo.translation;
    } else {
      return exmployee.translations[0].translation;
    }
  } else {
    return undefined;
  }
}
