import { Box, SimpleGrid, VStack } from "@chakra-ui/layout";
import { Divider, Image } from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { BannerWithHeading } from "../../generic/layout/bannerWithHeading";
import { Heading } from "../../generic/layout/heading";
import { MaxWidthContainer } from "../../generic/layout/maxWidthContainer";
import { servicesList } from "./services-list";
import { ServiceCard } from "./ServiceCard";
import greenerArbitrationLogo from "./media/greenerArbitrationLogo.jpeg";
import { Header } from "../../generic/layout/header";
import { HeadingMedium } from "../../generic/layout/headingMedium";

export function ServicesDetails({}: {}) {
  const { languageCode, service } = useParams();
  const { t } = useTranslation();

  return (
    <VStack spacing={0}>
      <BannerWithHeading bgUrl="/media/bg.jpg" headingText={t("services")} />

      <Box py={8}>
        <MaxWidthContainer>
          <HeadingMedium>
            {t("services." + service + ".title", { ns: "contentServices" })}
          </HeadingMedium>

          <Trans
            i18nKey={"services." + service + ".text"}
            ns="contentServices"
          ></Trans>

          {service == "arbitration" && (
            <span>
              <Divider mt={10} mb={6} />
              <SimpleGrid
                templateColumns={{ base: "1fr", md: "180px 1fr" }}
                flexDirection="column"
                spacing={8}
                w={"100%"}
              >
                <Box>
                  <Image
                    src={greenerArbitrationLogo}
                    w="100%"
                    objectFit="cover"
                    maxW={{ base: "50%", md: "100%" }}
                  />
                </Box>
                <Box>
                  <Trans
                    i18nKey={"services." + service + ".extra"}
                    ns="contentServices"
                  ></Trans>
                </Box>
              </SimpleGrid>
            </span>
          )}

          <Divider mt={10} mb={6} />
          {/*  <Heading>{t("services.more")}</Heading> */}
          <SimpleGrid
            spacing={4}
            templateColumns="repeat(auto-fill, minmax(350px, 1fr))"
          >
            {servicesList.map((serviceItem) => {
              return (
                //serviceItem !== service?.toString() &&
                <ServiceCard
                  key={serviceItem.service}
                  service={serviceItem.service}
                  color={serviceItem.color}
                  isSmall={true}
                />
              );
            })}
          </SimpleGrid>
        </MaxWidthContainer>
      </Box>
    </VStack>
  );
}
