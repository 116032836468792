import { Trans } from "react-i18next";

export function ImprintContent() {
  return (
    <Trans
      i18nKey={"text"}
      ns={"contentImprint"}
      components={{ ul: <ul />, li: <li /> }}
    ></Trans>
  );
}
