import { companyColors } from "../../../global/companyColors";
import img20230914 from "./media/2023-09-14.png";
import img20230907 from "./media/2023-09-07.png";
import img20230613 from "./media/2023-06-13.png";
import img20230524 from "./media/2023-05-24.png";
import img20230504 from "./media/2023-05-04.png";
import img20230414 from "./media/2023-04-14.png";
import img20230220 from "./media/2023-02-20.png";
import img20231207 from "./media/2023-12-07.jpg";
import img20230706 from "./media/2023-07-06.jpg";
import img20231222 from "./media/2023-12-22.jpg";
import img20240118 from "./media/2024-01-18.jpg";
import img20240125 from "./media/2024-01-25.jpg";

import wamAvatar from "./media/avatars/dr-wajma-mangal.png";
import malteStuebinger from "./media/avatars/malte-stuebinger.jpeg";

export type Activity = ActivityLinkedIn | ActivityMlEvent;
export type Author = {
  displayName: string;
  avatarUrl: string;
};

export type ActivityLinkedIn = {
  title: string;
  dateTimeUtcIso: string;
  shortDescription: string;
  author?: Author | undefined;
  imageUrl?: string | undefined;
  type: "linkedin-post";
  linkedInPostUrl: string;
};

export type ActivityMlEvent = {
  title: string;
  dateTimeUtcIso: string;
  shortDescription: string;
  author?: Author | undefined;
  imageUrl?: string | undefined;
  type: "ml-event";
  mlEventId: string;
};

export const authorGreenerArbitrations: Author = {
  displayName: "Campaign for Greener Arbitrations",
  avatarUrl:
    "https://images.squarespace-cdn.com/content/v1/5ede46ce84784b1c50e9a00f/1592301322227-2DWYZ86UDJF4KKKV0OT5/LOGO+Green+Arbitrations+Campaign.jpg?format=300w",
};

export const authorMangalLegal: Author = {
  displayName: "MANGAL LEGAL",
  avatarUrl: "/media/Icon_weiss_300x300.jpg",
};

export const authorDrWajmaMangal: Author = {
  displayName: "Dr. Wajma Mangal",
  avatarUrl: wamAvatar,
};

export const authorDrMalteStuebinger: Author = {
  displayName: "Dr. Malte Stübinger",
  avatarUrl: malteStuebinger,
};

export const activitiesList: { activity: Activity; color: string }[] = [
  {
    activity: {
      title: "DIS40 Nord Neujahrsbrunch",
      dateTimeUtcIso: "2024-01-18T16:45:00Z",
      author: authorDrWajmaMangal,
      imageUrl: img20240118,
      shortDescription:
        "Was für ein schöner DIS40 Nord Jahresauftakt! Wir freuen uns auf ein abwechslungsreiches 2024 mit spannenden Veranstaltungen. Stay tuned!",
      type: "linkedin-post",
      linkedInPostUrl:
        "https://www.linkedin.com/posts/activity-7155506853043445760-k0NG?utm_source=share&utm_medium=member_desktop",
    },
    color: companyColors.blue[400],
  },

  {
    activity: {
      title: "Vis Moot",
      dateTimeUtcIso: "2024-01-25T16:45:00Z",
      author: authorMangalLegal,
      imageUrl: img20240125,
      shortDescription:
        "🌟 One of our core values at MANGAL LEGAL is promoting young talents and empowering the next generation of legal professionals 🌟",
      type: "linkedin-post",
      linkedInPostUrl:
        "https://www.linkedin.com/posts/mangal-legal-rechtsanwaltsgesellschaft-mbh_international-arbitration-cisg-activity-7157726789211951106-1J1G?utm_source=share&utm_medium=member_desktop",
    },
    color: companyColors.blue[400],
  },

  {
    activity: {
      title: "Happy Birthday MANGAL LEGAL",
      dateTimeUtcIso: "2023-12-22T16:45:00Z",
      author: authorDrWajmaMangal,
      imageUrl: img20231222,
      shortDescription:
        "I cannot believe one year has already passed…This is not only the time to take a step back and to have a look at this amazing first year and the blessings we had as a new law firm but it is also the time to certainly say THANK YOU! ",
      type: "linkedin-post",
      linkedInPostUrl:
        "https://www.linkedin.com/posts/activity-7143862528220004352-DrlG?utm_source=share&utm_medium=member_desktop",
    },
    color: companyColors.blue[400],
  },
  {
    activity: {
      title:
        "DIS40 Nord Event: Summer Arbitration Cocktails mit Impulsvortrag zur KI",
      dateTimeUtcIso: "2023-07-06T16:45:00Z",
      author: authorDrMalteStuebinger,
      imageUrl: img20230706,
      shortDescription:
        "Die ausgebuchten DIS40 Nord Summer Arbitration Cocktails gestern Abend waren ein voller Erfolg. Gerade der Deep Dive in die Grundlagen der Funktionsweise der Large Language Models hat uns allen ein besseres Verständnis dafür vermittelt, wo die Technik uns heute auch im juristischen Bereich schon helfen kann, wo aber auch ihre Grenzen erreicht sind - und wo Haftungsfallen drohen.",
      type: "linkedin-post",
      linkedInPostUrl:
        "https://www.linkedin.com/posts/dr-malte-stuebinger_ai-arbitration-disputeresolution-activity-7083006168322723840-bEBI/?utm_source=share&utm_medium=member_desktop",
    },
    color: companyColors.blue[400],
  },
  {
    activity: {
      title: "DIS40 Nord: Buntes Weihnachtsmarkttreffen",
      dateTimeUtcIso: "2023-12-07T16:45:00Z",
      author: authorDrMalteStuebinger,
      imageUrl: img20231207,
      shortDescription:
        "Schon ist das erste Jahr unserer Amtszeit als DIS40 Nord Regionalkoordinatoren vorbei und ich denke an jede einzelne Veranstaltung mit einem großen Lächeln zurück. Der Weihnachtsmarktbesuch auf dem WinterPride am letzten Donnerstag war da keine Ausnahme.",
      type: "linkedin-post",
      linkedInPostUrl:
        "https://www.linkedin.com/posts/dr-malte-stuebinger_savethedate-staytuned-dis40-activity-7141375688065912832-yK2C/?utm_source=share&utm_medium=member_desktop",
    },
    color: companyColors.blue[400],
  },

  {
    activity: {
      title: "Best Lawyers: Ones to Watch in Germany™",
      dateTimeUtcIso: "2022-09-24T16:45:00Z",
      author: authorDrWajmaMangal,
      shortDescription:
        "Grateful and honored to be included in the 2023 edition of Best Lawyers: Ones to Watch in Germany™ in cooperation with Handelsblatt for International Arbitration for the first time. Many thanks to my peers for the recognition and congratulations to all those listed.",
      type: "linkedin-post",
      linkedInPostUrl:
        "https://www.linkedin.com/posts/activity-6946129484202844160-XcYC?utm_source=share&utm_medium=member_desktop",
    },
    color: companyColors.blue[400],
  },
  {
    activity: {
      title: "Amt der Regionalkoordinatoren der #DIS40 #Nord übernommen",
      dateTimeUtcIso: "2023-01-06T16:45:00Z",
      author: authorDrWajmaMangal,
      shortDescription:
        "Ich freue mich gemeinsam mit Dr. Malte Stübinger das Amt der Regionalkoordinatoren der #DIS40 #Nord übernehmen zu dürfen!  ",
      type: "linkedin-post",
      linkedInPostUrl:
        "https://www.linkedin.com/posts/activity-7009391065312952320-VS1T?utm_source=share&utm_medium=member_desktop",
    },
    color: companyColors.blue[500],
  },
  {
    activity: {
      title: "We are online",
      dateTimeUtcIso: "2023-02-20T16:45:00Z",
      author: authorMangalLegal,
      imageUrl: img20230220,
      shortDescription:
        "3…2…1… We are online 🎉 I am happy to share that our website www.mangal.legal is available now!",
      type: "linkedin-post",
      linkedInPostUrl:
        "https://www.linkedin.com/posts/activity-7032282771519610880-bxah?utm_source=share&utm_medium=member_desktop",
    },
    color: companyColors.blue[500],
  },
  {
    activity: {
      title: "DIS40 spring conferences in Munich",
      dateTimeUtcIso: "2023-05-04T16:45:00Z",
      author: undefined,
      imageUrl: img20230504,
      shortDescription:
        "It was great to meet so many friends and peers from the arbitration community at the Deutsche Institution für Schiedsgerichtsbarkeit (DIS) German Arbitration Institute’s spring conferences in Munich this week.",
      type: "linkedin-post",
      linkedInPostUrl:
        "https://www.linkedin.com/posts/activity-7060515942950166528-Oc-N?utm_source=share&utm_medium=member_desktop",
    },
    color: companyColors.blue[400],
  },
  {
    activity: {
      title: "AIJA - Half Year May Conference",
      dateTimeUtcIso: "2023-05-24T16:45:00Z",
      author: undefined,
      imageUrl: img20230524,
      shortDescription:
        "Under the motto „working together towards global justice“ 425 participants from around the world came together at the AIJA - International Association of Young Lawyers Half Year May Conference in The Hague this week.",
      type: "linkedin-post",
      linkedInPostUrl:
        "https://www.linkedin.com/posts/activity-7068237468772225024-B5Z0?utm_source=share&utm_medium=member_desktop",
    },
    color: companyColors.blue[400],
  },
  {
    activity: {
      title:
        "Recognized in this year‘s edition of Best Lawyers: Ones to Watch in Germany™ ",
      dateTimeUtcIso: "2023-06-13T16:45:00Z",
      author: authorDrWajmaMangal,
      imageUrl: img20230613,
      shortDescription:
        "I am proud and honored to be recognized in this year‘s edition of Best Lawyers: Ones to Watch in Germany™ in corporation with Handelsblatt. Many thanks to my peers for recognizing my work and passion. Congratulation to all those listed. 🥳",
      type: "linkedin-post",
      linkedInPostUrl:
        "https://www.linkedin.com/posts/activity-7075505421226029057-lSlv?utm_source=share&utm_medium=member_desktop",
    },
    color: companyColors.blue[500],
  },
  {
    activity: {
      title: "DIS40 Nord Interactive Brunch Workshop in Hamburg",
      dateTimeUtcIso: "2023-09-07T16:45:00Z",
      author: undefined,
      imageUrl: img20230907,
      shortDescription:
        "Yesterday, Dr. Malte Stübinger and I had our first DIS40 Nord Interactive Brunch Workshop in Hamburg on a topic that is close to our 💚: Campaign for Greener Arbitrations: The Green Pledge.",
      type: "linkedin-post",
      linkedInPostUrl:
        "https://www.linkedin.com/posts/activity-7105783644761251841-ddkL?utm_source=share&utm_medium=member_desktop",
    },
    color: companyColors.blue[500],
  },
  {
    activity: {
      title:
        "German Arbitration Institute invited to the DIS Autumn Conference 2023",
      dateTimeUtcIso: "2023-09-14T16:45:00Z",
      author: undefined,
      imageUrl: img20230914,
      shortDescription:
        "It was that time of the year again: The German Arbitration Institute had invited to the DIS Autumn Conference 2023 last week.",
      type: "linkedin-post",
      linkedInPostUrl:
        "https://www.linkedin.com/posts/activity-7109804560889643008-LTTH?utm_source=share&utm_medium=member_desktop",
    },
    color: companyColors.blue[400],
  },
  {
    activity: {
      title: "Sign of pledge",
      dateTimeUtcIso: "2023-04-14T16:45:00Z",
      author: authorGreenerArbitrations,
      imageUrl: img20230414,

      shortDescription:
        "Campaign for Greener Arbitrations: The Green Pledge Arbitration welcomes our latest signatories MANGAL LEGAL Rechtsanwaltsgesellschaft mbH",
      type: "linkedin-post",
      linkedInPostUrl:
        "https://www.linkedin.com/posts/green-arbitrations_join-us-campaign-for-greener-arbitrations-activity-7057354604593696769-vYkP?utm_source=share&utm_medium=member_ios",
    },
    color: companyColors.blue[400],
  },
];
