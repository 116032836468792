import React from "react";
import { HeadingProps } from "@chakra-ui/react";
import { Heading } from "./heading";

export function HeadingMedium({ children, ...rest }: HeadingProps) {
  return (
    <Heading fontSize={{ base: "18", sm: "20px", md: "25px" }} {...rest}>
      {children}
    </Heading>
  );
}
