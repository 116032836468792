import { Button } from "@chakra-ui/button";
import { Text } from "@chakra-ui/layout";
import { Box, Divider, Stack, VStack } from "@chakra-ui/layout";
import { Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ShowError } from "../../generic/ShowError";
import { ContactFormType } from "../../global/types";
import { Card, CardBody, CardFooter, HStack } from "@chakra-ui/react";
import { Heading } from "../../generic/layout/heading";
import { companyColors } from "../../global/companyColors";
import { ShowOk } from "../../generic/ShowOk";

import { FormField } from "../../generic/forms/FormField";

export function ContactForm({}: {}) {
  const [submitted, setSubmitted] = useState<
    "notSubmitted" | "submitted" | "error"
  >("notSubmitted");
  const { t } = useTranslation();
  const { languageCode } = useParams();
  const [errorMessage, setErrorMessage] = useState<string>("");

  const postRequest = async (payload: ContactFormType): Promise<any> => {
    const response = await fetch(
      "https://prod-17.germanywestcentral.logic.azure.com:443/workflows/0b845754103f40c18c49db36134b654a/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=gB_wPPB44uNCea3hxZB8NDYCKrKVahOZmp8MUPKB7C8",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    //

    if (!response.ok) {
      const json = await response.json();
      setErrorMessage(json.error.message);
      setSubmitted("error");
    } else {
      setErrorMessage("");
      setSubmitted("submitted");
    }

    return undefined;
  };

  return (
    <Box>
      <Stack spacing="3">
        <Heading fontSize={22} mb={4} mt={4}>
          {t("contactForm-submit-header")}
        </Heading>

        <Text>{t("contactForm-submit-intro")}</Text>
      </Stack>

      <Divider borderColor={companyColors.blue[600]} mt={8} mb={8} />

      <VStack w={"100%"}>
        <Formik<ContactFormType>
          initialValues={{
            Firstname: "",
            Lastname: "",
            Email: "",
            Phone: "",
            City: "",
            Company: "",
            Subject: "",
            LanguageCode: languageCode?.toUpperCase() || "",
          }}
          enableReinitialize={true}
          validationSchema={Yup.object({
            Email: Yup.string()
              .email(t("form-validationError-email").toString())
              .required(t("form-validationError-email").toString()),
            Firstname: Yup.string().required(
              t("form-validationError-firstname").toString()
            ),
            Lastname: Yup.string().required(
              t("form-validationError-lastname").toString()
            ),
            Subject: Yup.string().required(
              t("form-validationError-subject").toString()
            ),
          })}
          onSubmit={async (values, actions) => {
            await postRequest(values);
          }}
        >
          {(props: FormikProps<ContactFormType>) => (
            <Form style={{ width: "100%" }}>
              <VStack spacing={4} w={"100%"}>
                <FormField
                  fieldName="Firstname"
                  disabled={props.isSubmitting || submitted == "submitted"}
                />
                <FormField
                  fieldName="Lastname"
                  disabled={props.isSubmitting || submitted == "submitted"}
                />
                <FormField
                  fieldName="Company"
                  disabled={props.isSubmitting || submitted == "submitted"}
                />
                <FormField
                  fieldName="Email"
                  disabled={props.isSubmitting || submitted == "submitted"}
                />
                <FormField
                  fieldName="Phone"
                  disabled={props.isSubmitting || submitted == "submitted"}
                />
                <FormField
                  type="Textarea"
                  fieldName="Subject"
                  disabled={props.isSubmitting || submitted == "submitted"}
                />
                <Box fontSize="14px" textAlign="right">
                  {languageCode == "de" ? (
                    <Box>
                      Mit dem Absenden dieses Formulars bestätigen Sie, dass Sie
                      mit der Erhebung, Verarbeitung und Nutzung Ihrer Daten zum
                      Zwecke der Kontaktaufnahme einverstanden sind. Weitere
                      Informationen erhalten Sie in unserer{" "}
                      <a
                        href={`/${languageCode}/privacy-policy`}
                        target="_blank"
                      >
                        {t("privacy-policy")}
                      </a>
                      .
                    </Box>
                  ) : (
                    <Box>
                      By submitting this form, you confirm that you agree to the
                      collection, processing and use of your data for the
                      purpose of contacting you. For further information, please
                      see our{" "}
                      <a
                        href={`/${languageCode}/privacy-policy`}
                        target="_blank"
                      >
                        {t("privacy-policy").toLowerCase()}
                      </a>
                      .
                    </Box>
                  )}
                </Box>
                <Button
                  type="submit"
                  isDisabled={props.isSubmitting || submitted == "submitted"}
                  isLoading={props.isSubmitting}
                  loadingText={t("form-label-submitButton")}
                  alignSelf="flex-end"
                  colorScheme="blue"
                >
                  {t("form-label-submitButton")}
                </Button>
              </VStack>
            </Form>
          )}
        </Formik>
        {submitted == "error" && <ShowError errorMessage={errorMessage} />}
        {submitted == "submitted" && (
          <ShowOk okMessage={t("contactForm-message-submitted")} />
        )}
      </VStack>
    </Box>
  );
}
