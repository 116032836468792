import { Input as Input$ } from "@chakra-ui/input";
import { useField } from "formik";
import { FormProperties } from "../FormProperties";

export function Input(props: FormProperties) {
  const [field, meta] = useField(props.fieldName);
  return (
    <Input$
      id={props.fieldName}
      onChange={field.onChange}
      value={field.value}
      w={"100%"}
      disabled={props.disabled}
      variant="filled"
      placeholder={props.placeholder}
      isInvalid={meta.touched && meta.error !== undefined}
    />
  );
}
