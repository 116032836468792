import { Box, HStack, VStack } from "@chakra-ui/layout";
import { Trans, useTranslation } from "react-i18next";
import { Routes, Route } from "react-router";
import { Link, useParams } from "react-router-dom";
import { BannerWithHeading } from "../../generic/layout/bannerWithHeading";
import { MaxWidthContainer } from "../../generic/layout/maxWidthContainer";
import { PageNotFound } from "../pageNotFound";
import i18n from "../../generic/i18n";
import { useEffect, useState } from "react";
import { Button } from "@chakra-ui/button";
import { LawyerDisputeResolution } from "./LawyerDisputeResolution";
import { OpenPosition } from "./openPositionType";
import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Tfoot,
  Text,
} from "@chakra-ui/react";

export function CareerComponent() {
  return (
    <Routes>
      <Route index element={<CareerContent />} />
      <Route
        path="lawyer-dispute-resolution"
        element={<LawyerDisputeResolution />}
      />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export function CareerContent() {
  const { t } = useTranslation();
  const { languageCode } = useParams();
  const [openPositions, setOpenPositions] = useState<OpenPosition[]>([]);
  useEffect(() => {
    if (languageCode) {
      let openPositions: [] = i18n.t("website-content-career.openPositions", {
        returnObjects: true,
        lng: languageCode,
      });
      setOpenPositions(openPositions);
    }
  }, [languageCode]);
  return (
    <VStack spacing={0}>
      <BannerWithHeading bgUrl="/media/bg3.jpg" headingText={t("career")} />

      <Box py={8}>
        <MaxWidthContainer>
          <Trans i18nKey={"website-content-career.text"}></Trans>
          <br />
          <br />
          <VStack align="flex-start">
            {openPositions.map((pos, i) => (
              <HStack
                alignItems="flex-start"
                pl={2}
                pr={2}
                bg={i % 2 == 0 ? "gray.100" : undefined}
                w="100%"
                p={i % 2 == 0 ? 2 : undefined}
              >
                {pos.hasDetails ? (
                  <HStack spacing={2}>
                    <Box>{pos.title}</Box>
                    <Link to={pos.slug}>
                      <Button size="xs" colorScheme="blackAlpha">
                        {t("vacancy")}
                      </Button>
                    </Link>
                  </HStack>
                ) : (
                  <>{pos.title}</>
                )}
              </HStack>
            ))}
          </VStack>

          {/*  <ul key={languageCode}>
            {openPositions.map((pos, i) => (
              <li key={i}>
                {pos.title}{" "}
                {pos.hasDetails && (
                 
                )}
              </li>
            ))} */}
        </MaxWidthContainer>
      </Box>
    </VStack>
  );
}
