import { Box } from "@chakra-ui/layout";
import { useTranslation } from "react-i18next";
import { Heading } from "../../generic/layout/heading";
import { MaxWidthContainer } from "../../generic/layout/maxWidthContainer";
import { companyColors } from "../../global/companyColors";

export function PageNotFound() {
  const { t } = useTranslation();
  return (
    <Box bg={companyColors.gray[200]} py={8}>
      <MaxWidthContainer>
        <Heading>{t("page-not-found-header")}</Heading>
        {t("page-not-found-content")}
      </MaxWidthContainer>
    </Box>
  );
}
