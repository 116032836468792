import { Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import { Heading } from "../../generic/layout/heading";
import { MaxWidthContainer } from "../../generic/layout/maxWidthContainer";
import { companyColors } from "../../global/companyColors";
import { ContactForm } from "./contactForm";
import { ContactContent } from "./content";

export function ContactComponent() {
  const { t } = useTranslation();
  return (
    <Box bg={companyColors.blue[200]} py={8} w="100%">
      <MaxWidthContainer>
        <Heading>{t("contact")}</Heading>
        <Routes>
          <Route index element={<ContactContent />} />
          <Route path="request" element={<ContactForm />} />
          <Route path="*" element={<ContactContent />} />
        </Routes>
      </MaxWidthContainer>
    </Box>
  );
}
