import { HStack } from "@chakra-ui/layout";
import { Card, CardHeader, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { RxDoubleArrowRight } from "react-icons/rx";
import { Link, useParams } from "react-router-dom";
import { Heading } from "../../generic/layout/heading";
import { companyColors } from "../../global/companyColors";
import { Service } from "./services-list";

export function ServiceCard({
  service,
  color,
  isSmall,
}: {
  service: Service;
  color: string;
  isSmall?: boolean;
}) {
  const { languageCode } = useParams();
  const { t } = useTranslation(["translation", "contentServices"]);
  return (
    <Link to={`/${languageCode}/services/${service}`}>
      <Card
        backgroundColor={color}
        size={isSmall ? "sm" : "md"}
        _hover={{ boxShadow: "inset 0 0 100px 100px rgba(62, 69, 88, 0.1)" }}
        shadow={0}
      >
        <CardHeader>
          <HStack>
            <Text wordBreak="break-word" color={companyColors.blue[1000]}>
              {t("services." + service + ".title", { ns: "contentServices" })}
            </Text>{" "}
            <RxDoubleArrowRight size={20} />
          </HStack>
        </CardHeader>
        {/*       <CardBody>
            <Text>View a summary of all your customers over the last month.</Text>
          </CardBody> */}
      </Card>
    </Link>
  );
}
