import { WarningIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  VStack,
  Box,
  HStack,
  Tooltip,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export function ShowWarning({
  message,
  layout,
}: {
  message: string;
  layout?: "AlertBox" | "Icon";
}) {
  const { t } = useTranslation();

  return (
    <>
      {layout == "AlertBox" || layout == undefined ? (
        <Alert status="warning">
          <VStack alignItems="start" flexWrap="wrap">
            <HStack>
              <WarningIcon />
              <AlertTitle mr={2}>{t("note")}</AlertTitle>
            </HStack>

            <AlertDescription>{message}</AlertDescription>
          </VStack>
          {/* <CloseButton position="absolute" right="8px" top="8px" /> */}
        </Alert>
      ) : (
        <Tooltip label={message} fontSize="md">
          <WarningIcon />
        </Tooltip>
      )}
    </>
  );
}
