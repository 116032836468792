import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
  useDisclosure,
  VStack,
  Box,
  Text,
  Container,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { companyColors } from "../global/companyColors";
import { supportedLngs } from "./i18n";
import { Heading } from "./layout/heading";

export function CookieConsentComponent() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { languageCode } = useParams();

  const initialRef = React.useRef(null);

  const { isOpen, onOpen, onClose } = useDisclosure({
    isOpen: localStorage.getItem("useAnalytics") == undefined,
  });

  function close(accept: boolean) {
    localStorage.setItem("useAnalytics", accept ? "1" : "0");
    //window.location.href = window.location.href;
    navigate(window.location.pathname);
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      initialFocusRef={initialRef}
    >
      <ModalOverlay backdropFilter="blur(4px)" />
      <ModalContent py={6} mx={8}>
        <ModalBody py={2}>
          <Stack flex={1} spacing={{ base: 5, md: 7 }}>
            <Box display="flex" justifyContent="space-between" flexGrow={1}>
              <Heading mb={0} fontSize={20}>
                {t("cookie-consent-header")}
              </Heading>
              <HStack spacing={2}>
                {supportedLngs.map((lng) => (
                  <Link key={lng.code} to={`/${lng.code}`}>
                    {lng.shortName}
                  </Link>
                ))}
              </HStack>
            </Box>

            <VStack spacing="3" align="flex-start">
              <Text>{t("cookie-consent-content")}</Text>
              <Container m={0} p={0}>
                <Text>{t("cookie-consent-more-info")}</Text>
                <Link to={`/${languageCode}/privacy-policy`}>
                  {t("privacy-policy")}
                </Link>{" "}
                | <Link to={`/${languageCode}/imprint`}>{t("imprint")}</Link>
              </Container>
            </VStack>

            <Stack spacing={2} direction={{ base: "column", md: "row" }}>
              <Button
                ref={initialRef}
                bg={companyColors.blue[800]}
                color={"white"}
                _hover={{
                  bg: companyColors.blue[1000],
                }}
                onClick={() => close(true)}
              >
                {t("cookie-consent-accept")}
              </Button>
              <Button
                bg={companyColors.blue[400]}
                _hover={{
                  bg: companyColors.blue[1000],
                  color: "white",
                }}
                onClick={() => close(false)}
              >
                {t("cookie-consent-reject")}
              </Button>
            </Stack>
          </Stack>
        </ModalBody>

        {/*         <ModalFooter justifyContent="start" py={2}>
          
        </ModalFooter> */}
      </ModalContent>
    </Modal>
  );
}
