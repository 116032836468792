import { Box, VStack } from "@chakra-ui/layout";
import { Trans, useTranslation } from "react-i18next";
import { Routes, Route } from "react-router";
import { useParams } from "react-router-dom";
import { BannerWithHeading } from "../../generic/layout/bannerWithHeading";
import { MaxWidthContainer } from "../../generic/layout/maxWidthContainer";
import { PageNotFound } from "../pageNotFound";

export function CsrComponent() {
  return (
    <Routes>
      <Route index element={<CsrContent />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export function CsrContent() {
  const { t } = useTranslation();
  const { languageCode } = useParams();
  return (
    <VStack spacing={0}>
      <BannerWithHeading
        bgUrl="/media/bg5.jpg"
        headingText={t("corporate-social-responsibility")}
      />

      <Box py={8}>
        <MaxWidthContainer>
          <Trans i18nKey={"website-content-csr.text"}></Trans>
        </MaxWidthContainer>
      </Box>
    </VStack>
  );
}
