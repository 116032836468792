import { extendTheme } from "@chakra-ui/react";
import { companyColors } from "../global/companyColors";

//from example: https://codesandbox.io/s/chakra-change-focus-ring-color-c2yis?file=/src/theme.ts:49-620

export const maxWidth = "1300px";
export const defaultFontSize = 16;

const variantOutlined = () => ({
  field: {
    _focus: {
      borderColor: "var(--chakra-ui-focus-ring-color)",
      boxShadow: "0 0 0 2px var(--chakra-ui-focus-ring-color)",
    },
  },
});

const variantFilled = () => ({
  field: {
    border: "1px solid",
    borderColor: "gray.200",
    background: "white",
    _focus: {
      borderColor: "var(--chakra-ui-focus-ring-color)",
      boxShadow: "0 0 0 1px var(--chakra-ui-focus-ring-color)",
      background: "white",
    },
    _hover: {
      bg: "white",
    },

    // This does not work
    _autofill: {
      /* border: "1px solid transparent", */
      /* textFillColor: "#c6c6c6", */
      boxShadow: "0 0 0px 1000px white inset",
      transition: "background-color 5000s ease-in-out 0s",
    },
  },
});

const variantFlushed = () => ({
  field: {
    _focus: {
      borderColor: "var(--chakra-ui-focus-ring-color)",
      boxShadow: "0 1px 0 0 var(--chakra-ui-focus-ring-color)",
    },
  },
});

export const theme = extendTheme({
  coloredBg: {
    field: {
      border: "10px solid",
      borderColor: "gray.200",
      background: "gray.50",
      borderRadius: "full",
    },
  },
  colors: {
    /*     teal: {
      100: companyColors.teal[200],
      200: companyColors.teal[200],
      400: companyColors.teal[400],
      500: companyColors.teal[400],
      600: companyColors.teal[600],
      800: companyColors.teal[800],
      1000: companyColors.teal[1000],
    }, */
    blue: {
      100: companyColors.blue[200],
      200: companyColors.blue[200],
      400: companyColors.blue[400],
      500: companyColors.blue[600],
      600: companyColors.blue[700],
      800: companyColors.blue[800],
      1000: companyColors.blue[1000],
    },
    gray: {
      200: companyColors.gray[200],
      400: companyColors.gray[400],
      600: companyColors.gray[600],
      800: companyColors.gray[800],
      1000: companyColors.gray[1000],
    },
    orange: {
      100: undefined,
      200: companyColors.orange[200],
      400: companyColors.orange[400],
      500: companyColors.orange[500],
      600: companyColors.orange[600],
      800: undefined,
      1000: undefined,
    },
    /*     green: {
      100: companyColors.green[100],
      500: companyColors.green[500],
      600: companyColors.green[600],
      800: companyColors.green[800],
    }, */

    /*     yellow: {
      200: "#f8e2c3",
      400: "#f2ca91",
      600: "#eeb864",
      800: "#d19630",
      1000: "#765718",
    },
    red: {
      200: "#f5e1e0",
      400: "#e8b7b5",
      600: "#d8827f",
      800: "#ba5854",
      1000: "#69322f",
    }, */
  },

  styles: {
    global: {
      // styles for the `body`
      body: {
        color: companyColors.blue[1000],
        fontSize: defaultFontSize,
        wordBreak: "break-word",
      },
      // styles for the `a`
      a: {
        color: "gray.800",
      },

      // Create a CSS variable with the focus ring color desired.
      // rgba function does not work here so use the hex value.
      // Either :host,:root or html work. body does not work for
      // button, checkbox, radio, switch.
      // html: {
      ":host,:root": {
        "--chakra-ui-focus-ring-color": companyColors.teal[600],
      },
    },
  },
  shadows: {
    // This is also possible. Not sure I like inject this into
    // an existing theme section.
    // It creates a CSS variable named --chakra-shadows-focus-ring-color
    // 'focus-ring-color': 'rgba(255, 0, 125, 0.6)',
    outline: "0 0 0 3px var(--chakra-ui-focus-ring-color)",
  },
  components: {
    Input: {
      variants: {
        outline: variantOutlined,
        filled: variantFilled,
        flushed: variantFlushed,
      },
    },
    MenuButton: {
      borderBottomColor: companyColors.orange[400],
      borderBottomWidth: 4,
    },
    Box: {
      variants: {
        mainNav: {
          borderBottomColor: "transparent",
          display: "block",
          borderBottomWidth: 4,
          color: companyColors.blue[1000],

          fontSize: 18,
          fontWeight: 500,
          _hover: {
            borderBottomColor: companyColors.orange[200],
            borderBottomWidth: 4,
          },
        },
        linkButton: {
          borderBottomColor: "transparent",
          display: "inline-block",
          borderBottomWidth: 4,

          _hover: {
            borderBottomColor: companyColors.orange[200],
            borderBottomWidth: 4,
          },
        },
        mainNavSelected: {
          borderBottomColor: companyColors.orange[400],
          borderBottomWidth: 4,
          color: companyColors.blue[1000],
          fontSize: 18,
          fontWeight: 500,
          _hover: {
            borderBottomColor: companyColors.orange[200],
            borderBottomWidth: 4,
          },
        },
      },
    },
    Select: {
      variants: {
        outline: variantOutlined,
        filled: variantFilled,
        flushed: variantFlushed,
      },
    },
    Textarea: {
      variants: {
        outline: () => variantOutlined().field,
        filled: () => variantFilled().field,
        flushed: () => variantFlushed().field,
      },
    },
  },
});
