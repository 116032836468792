import { FormControl, FormErrorMessage } from "@chakra-ui/form-control";

import { SimpleGrid, Text } from "@chakra-ui/layout";
import { Box } from "@chakra-ui/layout";
import { layout } from "@chakra-ui/styled-system";
import { useField, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { companyColors } from "../../global/companyColors";
import { BookingItemType } from "../../global/types";
import { Input } from "./components/Input";
import { Radio } from "./components/Radio";
import { Select } from "./components/Select";
import { Textarea } from "./components/Textarea";

import { FormProperties } from "./FormProperties";

export interface FormFieldProps extends FormProperties {
  type?: "Input" | "Textarea" | "Select" | "Radio" | undefined;
  data?: string[] | undefined;
  layout?: "Vertical" | "Horizontal" | undefined;
}

export function FormField(props: FormFieldProps) {
  const [field, meta, helpers] = useField(props.fieldName);
  const { touched, errors } = useFormikContext<BookingItemType>();
  const { t } = useTranslation();

  return (
    <FormControl isInvalid={meta.error != undefined} w={"100%"}>
      <SimpleGrid
        templateColumns={{
          base: "1fr",
          md: props.layout == "Vertical" ? "1fr" : "0.5fr 1.6fr",
        }}
        mb={4}
        alignItems="center"
      >
        <Text>{t("form-label-" + props.fieldName.toLowerCase())}</Text>
        <Box>
          {(props.type == undefined || props.type == "Input") && (
            <Input
              fieldName={props.fieldName}
              disabled={props.disabled}
              placeholder={props.placeholder}
            />
          )}
          {props.type == "Textarea" && (
            <Textarea
              fieldName={props.fieldName}
              disabled={props.disabled}
              placeholder={props.placeholder}
            />
          )}
          {props.type == "Select" && (
            <Select
              fieldName={props.fieldName}
              disabled={props.disabled}
              placeholder={props.placeholder}
              data={props.data}
            />
          )}
          {props.type == "Radio" && (
            <Radio
              fieldName={props.fieldName}
              disabled={props.disabled}
              placeholder={props.placeholder}
              data={props.data}
            />
          )}

          {meta.touched && meta.error && (
            <FormErrorMessage mt={1}>{meta.error}</FormErrorMessage>
          )}
        </Box>
      </SimpleGrid>
    </FormControl>
  );
}
