import { Select as Select$ } from "@chakra-ui/react";
import { useField } from "formik";
import { useTranslation } from "react-i18next";
import { FormProperties } from "../FormProperties";

export function Select(props: FormProperties) {
  const [field, meta] = useField(props.fieldName);
  const { t } = useTranslation();
  return (
    <Select$
      id={props.fieldName}
      onChange={field.onChange}
      value={field.value}
      w={"100%"}
      disabled={props.disabled}
      variant="filled"
      placeholder={props.placeholder}
      isInvalid={meta.touched && meta.error !== undefined}
      defaultValue={undefined}
    >
      {props.data?.map((x) => (
        <option value={x}>{t(x)}</option>
      ))}
    </Select$>
  );
}
