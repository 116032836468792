import { VStack, Box, useBreakpointValue } from "@chakra-ui/react";
import { MaxWidthContainer } from "../../generic/layout/maxWidthContainer";
import { useParams } from "react-router-dom";
import { Heading } from "../../generic/layout/heading";
import { useTranslation } from "react-i18next";
import { companyColors } from "../../global/companyColors";
import { BannerWithHeading } from "../../generic/layout/bannerWithHeading";
import { useScroll, useSpring } from "framer-motion";
import { ServicesContent } from "../services/ServicesContent";
import { EmployeeList } from "../team/EmployeeList";
import { ContactContent } from "../contact/content";
import { Caroussel } from "./caroussel/caroussel";

export function LandingPage() {
  const { languageCode } = useParams();
  const { t } = useTranslation();

  const bg =
    useBreakpointValue(
      {
        base: "/media/bg_landing_mobile.jpg",
        md: "/media/bg_landing.jpg",
      },
      { ssr: false }
    ) || "/media/bg_landing.jpg";

  return (
    <VStack spacing={0}>
      <BannerWithHeading bgUrl={bg} headingText={t("landing.claim")} />

      <Caroussel />

      <Box h={50} />
      <Box>
        <ServicesContent showOnStartPage={true} />
        <Box h={50} />
        <EmployeeList />
      </Box>

      <Box bg={companyColors.blue[200]} pt={10} w="100%">
        <MaxWidthContainer>
          <Heading>{t("contact")}</Heading>
          <ContactContent />
          {/*  <Divider borderColor={companyColors.blue[800]} /> */}
        </MaxWidthContainer>
      </Box>
    </VStack>
  );
}
