import { Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Heading } from "../../generic/layout/heading";
import { MaxWidthContainer } from "../../generic/layout/maxWidthContainer";
import { companyColors } from "../../global/companyColors";
import { PrivacyPolicyContent } from "./content";

export function PrivacyPolicyComponent() {
  const { t } = useTranslation();
  return (
    <Box bg={companyColors.blue[200]} py={8}>
      <MaxWidthContainer>
        <>
          <Heading>{t("privacy-policy")}</Heading>
          <PrivacyPolicyContent />
        </>
      </MaxWidthContainer>
    </Box>
  );
}
