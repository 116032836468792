import { Box, SimpleGrid, VStack } from "@chakra-ui/layout";
import { Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { BannerWithHeading } from "../../../generic/layout/bannerWithHeading";
import { MaxWidthContainer } from "../../../generic/layout/maxWidthContainer";
import { activitiesList } from "./activities-list";
import { ActivityCard } from "./ActivityCard";
import React from "react";
import { LinkedInLink } from "../../../generic/layout/header";
import { Heading } from "../../../generic/layout/heading";
import { HeadingMedium } from "../../../generic/layout/headingMedium";

export function ActivitiesContent({
  showOnStartPage,
}: {
  showOnStartPage?: boolean | undefined;
}) {
  const { t } = useTranslation();
  const { languageCode } = useParams();
  return (
    <VStack spacing={0}>
      <BannerWithHeading bgUrl="/media/bg5.jpg" headingText={t("insights")} />

      <Box py={8}>
        <MaxWidthContainer>
          <HeadingMedium mb={10}>{t("activities")}</HeadingMedium>
          <Text fontSize={{ base: 18, md: 20 }}>
            {languageCode == "de" && (
              <React.Fragment>
                Informieren Sie sich über die neuesten Entwicklungen in unserer
                Kanzlei, z. B. über Redeauftritte, neue Veröffentlichungen oder
                die Anerkennung unserer Arbeit. Folgen Sie uns auf{" "}
                <a href={LinkedInLink} target="_blank">
                  LinkedIn
                </a>
                , damit Sie stets auf dem Laufenden sind.
              </React.Fragment>
            )}
            {languageCode == "en" && (
              <React.Fragment>
                Find out about the latest news from our law firm, e.g. speaking
                engagements, new releases or recognition of our work. Follow us
                on{" "}
                <a href={LinkedInLink} target="_blank">
                  LinkedIn
                </a>{" "}
                to ensure that you are staying up to date.
              </React.Fragment>
            )}
          </Text>
          <Box h={10} />
          <ActivitiesList />
        </MaxWidthContainer>
      </Box>
    </VStack>
  );
}

export function ActivitiesList({
  takeFirst,
}: {
  takeFirst?: number | undefined;
}) {
  return (
    <SimpleGrid
      spacing={4}
      templateColumns={{
        base: "",
        md: "repeat(auto-fill, 32%)",
      }}
    >
      {activitiesList
        .sort((a, b) => {
          if (a.activity.dateTimeUtcIso > b.activity.dateTimeUtcIso) {
            return -1;
          }
          if (a.activity.dateTimeUtcIso < b.activity.dateTimeUtcIso) {
            return 1;
          }
          return 0;
        })
        .slice(0, takeFirst ? takeFirst : activitiesList.length)
        .map((activity) => (
          <ActivityCard
            key={activity.activity.title}
            activity={activity.activity}
            color={activity.color}
          />
        ))}
    </SimpleGrid>
  );
}
