import { Employee } from "../employee-list";
import React from "react";
import imgDrWajmaMangal from ".././media/dr-wajma-mangal.png";
import i18n from "./../../../generic/i18n";

export const drWajmaMangal: Employee = {
  slug: "dr-wajma-mangal",
  displayName: "Dr. Wajma Mangal",
  title: "lawyer-female",
  isExternal: false,
  subTitle: "partner-female",

  img: imgDrWajmaMangal,
  languages: ["deu", "eng", "prs", "pst"],
};
