export const companyColors = {
  teal: {
    200: "",
    400: "",
    600: "",
    800: "",
    1000: "",
  },
  blue: {
    200: "#e8edf6",
    400: "#d7deed",
    500: "#b9c6d8",
    600: "#9faec3",
    700: "#75869E",
    800: "#4b5e79",
    1000: "#3e4558", //old: #292d3d
  },
  gray: {
    200: "#d6d6d6",
    400: "#c2c2c2",
    600: "#999999",
    800: "#656566",
    1000: "#1d1d1b",
  },
  orange: {
    100: "",
    200: "#FFD8C2",
    400: "#FFCBAD",
    500: "#FFBE99",
    600: "#FFB185",
    800: "",
  },
  green: {
    // ????
    100: "#e6f5e7",
    500: "#9fc2a1",
    600: "#9fc3a2",
    800: "#4a784d",
  },
  yellow: {
    200: "",
    400: "#d9ba7d",
    600: "",
    800: "",
    1000: "",
  },
  red: {
    200: "",
    400: "",
    600: "",
    800: "",
    1000: "",
  },
};
