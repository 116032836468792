import {
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  SimpleGrid,
  Skeleton,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react";
import { useMutation, UseMutationResult, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ShowLoading } from "../../../generic/ShowLoading";
import { ShowError } from "../../../generic/ShowError";
import { ShowOk } from "../../../generic/ShowOk";
import { BreadcrumbMenu } from "../../../generic/Breadcrumb";
import { EventDetailsHandler } from "./eventDetails";
import { EventSummary } from "./EventSummary";
import { BookingItemType } from "../../../global/types";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { companyColors } from "../../../global/companyColors";
import { t } from "i18next";
import { DateTime } from "luxon";
import { Heading } from "../../../generic/layout/heading";

export function EventCancel() {
  let { validationId } = useParams<{ validationId: string }>();

  return (
    <>
      {validationId ? (
        <EventCancelHandler validationId={validationId} />
      ) : (
        <ShowLoading />
      )}
    </>
  );
}

function EventCancelHandler({ validationId }: { validationId: string }) {
  const { t } = useTranslation();
  const [bookingDetails, setBookingDetails] = useState<BookingItemType>();
  const [bookingDetailsError, setBookingDetailsError] = useState<string>("");
  const [refetch, setRefetch] = useState(0);

  useEffect(() => {
    GetBookingDetails(validationId).then((response) => {
      if (response.ok) {
        response.json().then((json) => setBookingDetails(json));
      } else {
        response
          .json()
          .then((json) => setBookingDetailsError(json.error.message));
      }
    });
  }, [validationId, refetch]);

  return (
    <Box>
      {/*       <BreadcrumbMenu secondLevel="events" />
      <Box h={10} /> */}
      {bookingDetails ? (
        <>
          <EventDetailsHandler
            eventId={bookingDetails.EventId}
            mode="cancel"
            rightElement={
              <BookingDetails
                bookingDetails={bookingDetails}
                validationId={validationId}
                setRefetch={setRefetch}
              />
            }
          />

          <Box h={12} />
        </>
      ) : bookingDetailsError == "" ? (
        <Heading>
          <Skeleton h={"55px"} />
        </Heading>
      ) : (
        <ShowError errorMessage={bookingDetailsError || ""} />
      )}
    </Box>
  );
}

function BookingDetails({
  bookingDetails,
  validationId,
  setRefetch,
}: {
  bookingDetails: BookingItemType;
  validationId: string;
  setRefetch: Dispatch<SetStateAction<number>>;
}) {
  const { languageCode } = useParams();
  let url =
    "https://prod-13.germanywestcentral.logic.azure.com:443/workflows/deccc15b8f3449d093d99965f1b7b1e6/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=xXPiCVq3mGUbMn1qbmdQpSJjINiZa3UbWJ1ZlBm_nsU";
  const { data, status, isSuccess, refetch, error } = useQuery(
    ["emailValidation", validationId],
    async () => {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          BookingId: validationId,
          LanguageCode: languageCode?.toUpperCase(),
        }),
      };
      const res = await fetch(url, requestOptions);
      setRefetch(Math.random());
      if (res.status >= 400) {
        throw new Error(res.statusText);
      } else {
        if (res.type == "cors") {
        } else {
          return res.json();
        }
      }
    },
    {
      refetchOnWindowFocus: false,
      retry: false,

      enabled: false, // turned off by default, manual refetch is needed
    }
  );

  return (
    <Box>
      <Card
        w={"100%"}
        backgroundColor={companyColors.blue[200]}
        textColor={companyColors.blue[1000]}
      >
        <CardBody>
          <Heading fontSize={24}>{t("event-booking-details")}</Heading>
          <SimpleGrid
            templateColumns={{ base: "1fr 1fr", md: "1fr 1fr " }}
            flexDirection="row"
            spacing={2}
            justifyContent="space-between"
          >
            <div>{t("form-label-name")}</div>
            <div>
              {bookingDetails.Firstname} {bookingDetails.Lastname}
            </div>

            <div>{t("form-label-email")}</div>
            <div>{bookingDetails.Email}</div>

            <div>{t("event-label-bookingStatus")}</div>
            <div>
              {bookingDetails.Canceled ? (
                <Badge fontSize="1em" colorScheme="red">
                  {t("event-label-bookingStatus-canceled")}
                </Badge>
              ) : bookingDetails.WaitList ? (
                <Badge fontSize="1em" colorScheme="yellow">
                  {t("event-label-bookingStatus-waitList")}
                </Badge>
              ) : (
                <Badge fontSize="1em" colorScheme="green">
                  {t("event-label-bookingStatus-active")}
                </Badge>
              )}
            </div>

            <Button
              onClick={() => refetch()}
              isDisabled={
                status == "loading" ||
                status == "success" ||
                bookingDetails.Canceled != ""
              }
              isLoading={status == "loading"}
              loadingText={t("event-label-cancel")}
              alignSelf="flex-end"
              colorScheme="blue"
              type="submit"
              mt={4}
            >
              {t("event-label-cancel")}
            </Button>
          </SimpleGrid>
        </CardBody>
      </Card>
      <Box maxW={"512px"} mt={4}>
        {status == "error" && (
          <ShowError errorMessage={data?.error?.message || "Unknown Error."} />
        )}
        {status == "success" && <ShowOk okMessage={t("event-cancel-ok")} />}
      </Box>
    </Box>
  );
}

async function GetBookingDetails(bookingId: string): Promise<Response> {
  const url =
    "https://prod-25.germanywestcentral.logic.azure.com:443/workflows/3b52ff74775549b3a519cad061881b05/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=mZrXiJ4G7QP5pTdKlhLAi7DJlOgyQ7hUBOwQiwe-xvQ";
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ id: bookingId }),
  };
  return fetch(url, requestOptions);
}
