import React, { LegacyRef } from "react";
import {
  Box,
  Container as ChakraContainer,
  ContainerProps,
  useBreakpointValue,
} from "@chakra-ui/react";
import { maxWidth } from "../chakraUiTheme";

export function MaxWidthContainer({
  children,
  ref,
  ...rest
}: { ref?: any | undefined } & ContainerProps) {
  const px = useBreakpointValue({ base: 4, md: 10 }, { ssr: false });
  const w = useBreakpointValue(
    { base: "100%", lg: "100%", xl: maxWidth },
    { ssr: false }
  );

  return (
    <ChakraContainer ref={ref} px={px} w={w || maxWidth} maxWidth={w} {...rest}>
      {children}
    </ChakraContainer>
  );
}
