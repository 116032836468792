import {
  Radio as Radio$,
  RadioGroup as RadioGroup$,
  Stack,
} from "@chakra-ui/react";
import { useField } from "formik";
import { FormProperties } from "../FormProperties";

export function Radio(props: FormProperties) {
  const [field, meta] = useField(props.fieldName);
  return (
    <RadioGroup$
      id={props.fieldName}
      onChange={field.onChange}
      value={field.value}
      w={"100%"}
      placeholder={props.placeholder}
    >
      <Stack direction="row">
        {props.data?.map((x) => (
          <Radio$ value={x}>{x}</Radio$>
        ))}{" "}
      </Stack>
    </RadioGroup$>
  );
}
