import React from "react";

import {
  TranslatedInfo,
  Translations,
  websiteLanguages,
} from "../../generic/i18n";
import { kiraBehrmann } from "./employees/kira-behrmann";
import { rahnaGhazallKhaleqzoy } from "./employees/rahna-ghazall-khaleqzoy";
import { olgaSchaefer } from "./employees/olgaSchaefer";
import { sebastianPKnickmeier } from "./employees/sebastianPKnickmeier";
import { drWajmaMangal } from "./employees/drWajmaMangal";
import { elizabethHincapieH } from "./employees/elizabethHHincapie";
import { gretaNeubauerKonradies } from "./employees/gretaNeuerbauerKonradies";

export type Employee = {
  slug: string;
  displayName: string;
  title:
    | "legal-assistant-male"
    | "legal-assistant-female"
    | "lawyer-male"
    | "lawyer-female"
    | "office-manager-female"
    | "student-assistant-male"
    | "student-assistant-female";
  isExternal?: boolean | undefined;
  subTitle?: string | undefined;
  secondTitle?: string | undefined;
  hasProfile?: boolean | undefined;
  languages: Language[];

  img: string;
  translations?: Translations<EmployeeTranslatedInfo>[] | undefined;
  displayNameSuffix?: string | undefined;
};

export interface EmployeeTranslatedInfo {
  memberships?: string[] | undefined;
  awards?: string[] | undefined;
  publications?: string[] | undefined;
}

export type Language = "deu" | "eng" | "pst" | "prs" | "rus" | "esp";

export const employeeList: Employee[] = [
  drWajmaMangal,
  gretaNeubauerKonradies,

  /* sebastianPKnickmeier, */
  elizabethHincapieH,
  olgaSchaefer,
  rahnaGhazallKhaleqzoy,
  /*  kiraBehrmann, */
];
