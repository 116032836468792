import { Alert, AlertIcon } from "@chakra-ui/react";

export function ShowOk({ okMessage }: { okMessage: string }) {
  return (
    <>
      <Alert status="success">
        <AlertIcon />
        {okMessage}
      </Alert>
    </>
  );
}
