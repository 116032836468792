import { Box } from "@chakra-ui/layout";
import { useTranslation } from "react-i18next";
import { Heading } from "../../generic/layout/heading";
import { MaxWidthContainer } from "../../generic/layout/maxWidthContainer";
import { companyColors } from "../../global/companyColors";
import { ImprintContent } from "./content";

export function ImprintComponent() {
  const { t } = useTranslation();
  return (
    <Box bg={companyColors.blue[200]} py={8}>
      <MaxWidthContainer>
        <Heading>{t("imprint")}</Heading>
        <ImprintContent />
      </MaxWidthContainer>
    </Box>
  );
}
