import { companyColors } from "../../global/companyColors";

export type Service =
  | "startup-advisory"
  | "in-house-and-local-counsel"
  | "dubai-desk"
  | "litigation"
  | "arbitration"
  | "business-and-contract-law"
  | "international-commercial-and-contract-law"
  | "real-estate-and-construction-law"
  | "climate-change-litigation"
  | "esg-compliance";

export const servicesList: { service: Service; color: string }[] = [
  { service: "litigation", color: companyColors.blue[500] },
  { service: "arbitration", color: companyColors.blue[500] },
  { service: "climate-change-litigation", color: companyColors.blue[500] },
  {
    service: "international-commercial-and-contract-law",
    color: companyColors.blue[200],
  },
  {
    service: "real-estate-and-construction-law",
    color: companyColors.blue[200],
  },
  { service: "esg-compliance", color: companyColors.blue[200] },
  { service: "startup-advisory", color: companyColors.blue[400] },
  { service: "in-house-and-local-counsel", color: companyColors.blue[400] },
  { service: "dubai-desk", color: companyColors.blue[400] },
];
