import { Employee } from "../employee-list";
import React from "react";
import imgRahnaGhazallKhaleqzoy from ".././media/rahna-ghazall-khaleqzoy.png";

export const rahnaGhazallKhaleqzoy: Employee = {
  slug: "rahna-ghazall-khaleqzoy",
  displayName: "Rahna Khaleqzoy",
  title: "student-assistant-female",
  languages: ["deu", "eng", "prs", "pst"],
  isExternal: false,
  hasProfile: false,
  img: imgRahnaGhazallKhaleqzoy,
  translations: [],
};
