import { VStack, Image, Box, Button, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { CompanyAddress } from "../../../generic/CompanyAddress";
import { ContactForm } from "../contactForm";
import { ContactFormModal } from "../contactFormModal";
import LocationImage from "../media/Location.png";
export function ContactContentDe() {
  const [showMap, setShowMap] = useState(false);
  const { t } = useTranslation();
  const { languageCode } = useParams();

  return (
    <VStack spacing={8} alignItems="start">
      <Box>
        <CompanyAddress showFullContact={true} />
      </Box>{" "}
      <ContactFormModal />
      {!showMap && (
        <Box width={"100%"} pos="relative">
          <Box pos="absolute" top="1" right="1" backgroundColor="white" p={2}>
            <Button onClick={() => setShowMap(true)} fontSize="14px">
              {t("show-gmaps")}
            </Button>
            <Text fontSize="12px" textAlign="center" maxWidth={170} mt={2}>
              {t("gmaps-disclaimer-privacy-policy")}{" "}
              <Link to={`/${languageCode}/data-privacy`}>
                {t("privacy-policy")}
              </Link>
              .
            </Text>
          </Box>
          <Image src={LocationImage} w="100%" h="500px" objectFit="cover" />
        </Box>
      )}
      <Box h={1} />
      {showMap && (
        <Box w={"100%"}>
          <iframe
            width="100%"
            height="500"
            src="https://maps.google.com/maps?q=hohe%20bleichen%2024,%20hamburg&t=&z=17&ie=UTF8&iwloc=&output=embed"
            scrolling="no"
          ></iframe>
        </Box>
      )}
    </VStack>
  );
}
