import React from "react";
import { Heading as ChakraHeading, HeadingProps } from "@chakra-ui/react";

export function Heading({ children, ...rest }: HeadingProps) {
  return (
    <ChakraHeading
      lineHeight={1.1}
      fontWeight={500}
      mb={8}
      fontSize={{ base: "25", sm: "30px", md: "35px", lg: "35px" }}
      {...rest}
      as="h1"
    >
      {children}
    </ChakraHeading>
  );
}
