import { Box, Divider, SimpleGrid, VStack } from "@chakra-ui/layout";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { BannerWithHeading } from "../../generic/layout/bannerWithHeading";
import { Heading } from "../../generic/layout/heading";
import { MaxWidthContainer } from "../../generic/layout/maxWidthContainer";
import { companyColors } from "../../global/companyColors";
import { employeeList } from "./employee-list";
import { EmployeeCard } from "./EmployeeCard";

export function EmployeeList() {
  const { t } = useTranslation();
  const { languageCode } = useParams();
  return (
    <VStack spacing={0}>
      <BannerWithHeading bgUrl="/media/bg2.jpg" headingText={t("team")} />
      <MaxWidthContainer py={8} w="100%">
        <VStack spacing={8}>
          {employeeList.map((employee, i) => (
            <React.Fragment key={employee.slug}>
              <EmployeeCard employee={employee} />
              {i + 1 < employeeList.length && (
                <Divider borderColor={companyColors.blue[400]} />
              )}
            </React.Fragment>
          ))}
        </VStack>
      </MaxWidthContainer>
    </VStack>
  );
}
