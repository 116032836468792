import { Box, HStack, VStack } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";
import { Avatar, Card, CardFooter, CardHeader, Text } from "@chakra-ui/react";
import { DateTime } from "luxon";
import { useState } from "react";
import { act } from "react-dom/test-utils";
import { useTranslation } from "react-i18next";
import { FaLinkedin } from "react-icons/fa";
import { RxDoubleArrowRight } from "react-icons/rx";
import { Link, useParams } from "react-router-dom";
import { Heading } from "../../../generic/layout/heading";
import { companyColors } from "../../../global/companyColors";
import { Activity } from "./activities-list";
import { LinkButton } from "../../../generic/LinkButton";

export function ActivityCard({
  activity,
  color,
  isSmall,
}: {
  activity: Activity;
  color: string;
  isSmall?: boolean;
}) {
  const { languageCode } = useParams();
  const { t } = useTranslation();
  const [linkTo, setLinkTo] = useState("");

  return (
    <VStack>
      <Card
        /* backgroundColor={color} */
        size={isSmall ? "sm" : "md"}
        direction={"column"}
        overflow="hidden"
        variant="filled"
        bgColor={"white"}
        border="1px"
        borderColor={companyColors.blue[200]}
      >
        {activity.imageUrl && (
          <Image
            objectFit="cover"
            w={"100%"}
            h="180px"
            src={activity.imageUrl}
            alt=""
          />
        )}
        <CardHeader>
          <VStack alignItems="flex-start" height="170px">
            {activity.author ? (
              <HStack>
                <Avatar
                  bg={companyColors.blue[500]}
                  textColor={companyColors.blue[1000]}
                  name={activity.author.displayName}
                  src={activity.author.avatarUrl}
                />
                <VStack alignItems="flex-start">
                  <Text
                    wordBreak="break-word"
                    color={companyColors.blue[1000]}
                    fontWeight="bold"
                  >
                    {activity.author.displayName}
                  </Text>
                  <HStack>
                    {activity.type == "linkedin-post" ? (
                      <FaLinkedin size="20" color={companyColors.blue[1000]} />
                    ) : (
                      <Box></Box>
                    )}{" "}
                    <Text color={companyColors.blue[1000]}>
                      {DateTime.fromISO(
                        activity.dateTimeUtcIso
                      ).toRelativeCalendar({
                        locale: languageCode,
                        unit: "months",
                      })}
                    </Text>
                  </HStack>
                </VStack>
              </HStack>
            ) : (
              <HStack>
                {activity.type == "linkedin-post" ? (
                  <FaLinkedin size="20" color={companyColors.blue[1000]} />
                ) : (
                  <Box></Box>
                )}{" "}
                <Text color={companyColors.blue[1000]}>
                  {DateTime.fromISO(activity.dateTimeUtcIso).toRelativeCalendar(
                    {
                      locale: languageCode,
                      unit: "months",
                    }
                  )}
                </Text>
              </HStack>
            )}

            <Text
              wordBreak="break-word"
              color={companyColors.blue[1000]}
              fontWeight="bold"
              fontSize="14"
              noOfLines={[2]}
            >
              {" "}
              {activity.title}{" "}
            </Text>
            <Text
              wordBreak="break-word"
              color={companyColors.blue[1000]}
              fontSize="13"
              noOfLines={[3]}
            >
              {t(activity.shortDescription)}
            </Text>
          </VStack>
        </CardHeader>
        {activity.type == "linkedin-post" && (
          <CardFooter pt={0}>
            <LinkButton
              label={t("activities-read-full-article")}
              href={activity.linkedInPostUrl}
              target="_blank"
            />
          </CardFooter>
        )}
      </Card>
    </VStack>
  );
}
