import { Box, VStack } from "@chakra-ui/layout";
import { Divider } from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";
import { Routes, Route } from "react-router";
import { useParams } from "react-router-dom";
import { BannerWithHeading } from "../../generic/layout/bannerWithHeading";
import { Heading } from "../../generic/layout/heading";
import { MaxWidthContainer } from "../../generic/layout/maxWidthContainer";
import { companyColors } from "../../global/companyColors";
import { PageNotFound } from "../pageNotFound";
import {
  ActivitiesContent,
  ActivitiesList,
} from "./activities/ActivitiesContent";
import { EventComponent } from "./events";
import { EventList } from "./events/eventList";
import { LinkButton } from "../../generic/LinkButton";
import { HeadingMedium } from "../../generic/layout/headingMedium";

export function InsightsComponent() {
  return (
    <Routes>
      {/* <Route index element={<InsightsContent />} /> */}
      <Route path="events/*" element={<EventComponent />} />
      <Route path="activities" element={<ActivitiesContent />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export function InsightsContent() {
  const { t } = useTranslation();
  const { languageCode } = useParams();
  return (
    <VStack spacing={0} pb={{ base: 0, md: 10 }}>
      <BannerWithHeading bgUrl="/media/bg5.jpg" headingText={t("insights")} />

      <MaxWidthContainer pt={8} pb={8}>
        <EventList takeFirst={3} />
        <Box mt={10}>
          <LinkButton label={t("see-all-events")} link="events" />
        </Box>
      </MaxWidthContainer>
      <MaxWidthContainer
        bg={companyColors.blue[200]}
        py={10}
        mt={10}
        borderBottomWidth={{ base: "1px", md: "0px" }}
        borderBottomColor={{ base: companyColors.blue[400], md: undefined }}
      >
        <HeadingMedium mb={10} mt={0}>
          {t("latest-activities")}
        </HeadingMedium>
        <ActivitiesList takeFirst={3} />
        <Box mt={10}>
          <LinkButton label={t("see-all-activities")} link="activities" />
        </Box>
      </MaxWidthContainer>
    </VStack>
  );
}
