import { DateTime, Settings } from "luxon";
import { useTranslation } from "react-i18next";
import { EventItemType } from "../../../global/types";
import { Heading } from "../../../generic/layout/heading";
import { StatGroup, Stat } from "@chakra-ui/stat";
import {
  Badge,
  Box,
  Button,
  Collapse,
  IconButton,
  Image,
  SimpleGrid,
  Skeleton,
  useDisclosure,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { GetDaysLeft } from "./helper/GetDaysLeft";
import { ViewIcon } from "@chakra-ui/icons";
import React from "react";
import { StatItem } from "./StatItem";

export function EventSummary({
  eventItem,
  loading,
}: {
  eventItem: EventItemType | undefined;
  loading: boolean;
}) {
  const { t } = useTranslation();
  const { languageCode } = useParams();
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      {eventItem?.SaveTheDate && (
        <Badge fontSize="1em" colorScheme="red" mb={4}>
          SAVE THE DATE:
        </Badge>
      )}
      <Heading mb={2}>
        {loading && eventItem == undefined ? (
          <Skeleton h={"55px"} />
        ) : (
          <>
            {languageCode == "en" && eventItem?.NameEn
              ? eventItem?.NameEn
              : eventItem?.NameDe}
          </>
        )}
      </Heading>
      <Heading
        fontWeight={400}
        fontSize={{ base: "16px", sm: "16px", md: "20px", lg: "20px" }}
      >
        {loading && eventItem == undefined ? (
          <Skeleton h={"25px"} />
        ) : languageCode == "en" && eventItem?.SubtitleEn ? (
          eventItem?.SubtitleEn
        ) : (
          eventItem?.SubtitleDe
        )}
      </Heading>

      <SimpleGrid
        templateColumns={{ base: "1fr", md: "1fr 1fr 1fr " }}
        flexDirection="column"
        spacing={6}
        justifyContent="space-between"
      >
        <StatItem
          label={t("event-date").toString()}
          text={
            eventItem !== undefined ? (
              <>
                {DateTime.fromISO(eventItem.StartTime).toFormat("dd.MM.yyyy")}
                {eventItem.EndTime &&
                  DateTime.fromISO(eventItem.StartTime).toFormat(
                    "dd.MM.yyyy"
                  ) !=
                    DateTime.fromISO(eventItem.EndTime).toFormat(
                      "dd.MM.yyyy"
                    ) && (
                    <>
                      {" "}
                      -{" "}
                      {DateTime.fromISO(eventItem.EndTime).toFormat(
                        "dd.MM.yyyy"
                      )}
                    </>
                  )}
              </>
            ) : loading ? (
              <Skeleton height="20px" />
            ) : undefined
          }
        />
        {eventItem && eventItem?.StartTime.includes("00:00:00") != true && (
          <StatItem
            label={t("event-from-to").toString()}
            text={
              loading && eventItem == undefined ? (
                <Skeleton height="20px" />
              ) : (
                <span>
                  {DateTime.fromISO(eventItem?.StartTime).toFormat("HH:mm") +
                    (eventItem?.EndTime
                      ? " - " +
                        DateTime.fromISO(eventItem?.EndTime).toFormat("HH:mm")
                      : "")}
                  {languageCode == "de" ? " Uhr" : "h"}
                </span>
              )
            }
          />
        )}

        {/*           <StatHelpText>
            {eventItem?.RegistrationDeadline &&
              GetDaysLeft(eventItem?.RegistrationDeadline)}{" "}
            {t("event-registration-deadline-days-left")}
          </StatHelpText> */}

        <StatItem
          label={t("event-host").toString()}
          text={
            loading && eventItem == undefined ? (
              <Skeleton height="20px" />
            ) : eventItem?.Location != "*" ? (
              eventItem?.Location
            ) : (
              eventItem?.Address
            )
          }
          subText={
            loading && eventItem == undefined ? (
              <Skeleton height="16px" />
            ) : eventItem?.Location != "*" ? (
              eventItem?.Address
            ) : (
              ""
            )
          }
        />
      </SimpleGrid>

      <Box
        color="white"
        mt="4"
        mb={4}
        bg="teal.500"
        rounded="md"
        borderColor="blue.200"
        borderWidth={4}
      >
        {loading && eventItem == undefined ? (
          <Skeleton height="340px" />
        ) : eventItem?.CustomPicture ? (
          <Image src={eventItem?.CustomPicture}></Image>
        ) : (
          <iframe
            width="100%"
            height="340"
            src={`https://maps.google.com/maps?q=${encodeURIComponent(
              eventItem?.Location || ""
            )}%20${encodeURIComponent(
              eventItem?.Address || ""
            )}&t=&z=17&ie=UTF8&iwloc=&output=embed`}
            scrolling="no"
          ></iframe>
        )}
      </Box>
    </>
  );
}
