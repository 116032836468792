import { Box, HStack, VStack } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";
import {
  Avatar,
  Card,
  CardFooter,
  CardHeader,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import { DateTime } from "luxon";
import { useState } from "react";
import { act } from "react-dom/test-utils";
import { useTranslation } from "react-i18next";
import { FaLinkedin } from "react-icons/fa";
import { RxDoubleArrowRight } from "react-icons/rx";
import { Link, useParams } from "react-router-dom";
import { Heading } from "../../../generic/layout/heading";
import { companyColors } from "../../../global/companyColors";

import { LinkButton } from "../../../generic/LinkButton";
import { EventItemType } from "../../../global/types";

export function EventCard({
  eventItem,
  isLoading,
  isSmall,
}: {
  eventItem?: EventItemType | undefined;
  isLoading?: boolean | undefined;
  isSmall?: boolean | undefined;
}) {
  const { languageCode } = useParams();
  const { t } = useTranslation();
  const [linkTo, setLinkTo] = useState("");

  return (
    <VStack>
      <Card
        /* backgroundColor={color} */
        size={isSmall ? "sm" : "md"}
        direction={"column"}
        overflow="hidden"
        variant="filled"
        bgColor={"white"}
        border="1px"
        borderColor={companyColors.blue[200]}
        borderTopWidth="10px"
        borderTopColor={companyColors.blue[200]}
        w="100%"
      >
        {/*         <Image
          objectFit="cover"
          w={"100%"}
          h="180px"
          src={`/media/events/${eventItem.EventId}.jpg`}
          alt=""
        />
 */}
        <CardHeader>
          <VStack
            spacing={{ base: 2, md: 4 }}
            alignItems="start"
            flexWrap="wrap"
            height="170px"
          >
            {isLoading && <Skeleton height="20px" w={"80%"} />}
            <HStack>
              {eventItem && (
                <>
                  <Text color={companyColors.blue[1000]}>
                    {DateTime.fromISO(eventItem.StartTime).toFormat(
                      "dd.MM.yyyy"
                    )}
                  </Text>

                  <Text color={companyColors.blue[1000]}> / Hamburg</Text>
                </>
              )}
            </HStack>
            <VStack spacing={0} alignItems="start" flexWrap="wrap">
              {isLoading ? (
                <>
                  <Skeleton height="20px" w={"80%"} />
                  <Skeleton height="20px" w={"80%"} />
                  <Skeleton height="20px" w={"80%"} />
                </>
              ) : (
                <Text
                  wordBreak="break-word"
                  color={companyColors.blue[1000]}
                  fontWeight="bold"
                  fontSize="14"
                  noOfLines={[1]}
                >
                  {languageCode == "en" && eventItem?.NameEn
                    ? eventItem?.NameEn
                    : eventItem?.NameDe}
                </Text>
              )}
              {isLoading ? (
                <Skeleton height="20px" w={"80%"} />
              ) : (
                <Text
                  wordBreak="break-word"
                  color={companyColors.blue[1000]}
                  fontSize="13"
                  noOfLines={[1]}
                >
                  {languageCode == "en" && eventItem?.SubtitleEn
                    ? eventItem?.SubtitleEn
                    : eventItem?.SubtitleDe}
                </Text>
              )}
            </VStack>
            {isLoading ? (
              <Skeleton height="20px" w={"80%"} />
            ) : (
              <Text
                wordBreak="break-word"
                color={companyColors.blue[1000]}
                fontSize="13"
                noOfLines={[3]}
              >
                {languageCode == "en" && eventItem?.IntroTextEn
                  ? eventItem?.IntroTextEn
                  : eventItem?.IntroTextDe}
              </Text>
            )}
          </VStack>
        </CardHeader>

        <CardFooter pt={0}>
          {isLoading ? (
            <Skeleton height="23px" w={"80%"} />
          ) : (
            <>
              {eventItem && (
                <LinkButton
                  label={t("activities-read-full-article")}
                  href={
                    "/" + languageCode + "/insights/events/" + eventItem.EventId
                  }
                />
              )}
            </>
          )}
        </CardFooter>
      </Card>
    </VStack>
  );
}
