import { Routes, Route } from "react-router";
import { PageNotFound } from "../pageNotFound";
import { EmployeeList } from "./EmployeeList";
import { EmployeeDetails } from "./EmployeeDetails";

export function TeamComponent() {
  return (
    <Routes>
      <Route index element={<EmployeeList />} />
      <Route path=":employeeSlug" element={<EmployeeDetails />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}
