import { VStack } from "@chakra-ui/layout";
import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Tfoot,
  Text,
  useBreakpointValue,
  Box,
  Skeleton,
  SimpleGrid,
} from "@chakra-ui/react";
import { DateTime } from "luxon";
import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Heading } from "../../../generic/layout/heading";
import { HeadingMedium } from "../../../generic/layout/headingMedium";
import { ShowError } from "../../../generic/ShowError";
import { ShowLoading } from "../../../generic/ShowLoading";
import { EventItemType } from "../../../global/types";
import { EventCard } from "./EventCard";
import { EventListItem } from "./eventListItem";

export function EventList({ takeFirst }: { takeFirst?: number | undefined }) {
  let url =
    "https://prod-28.germanywestcentral.logic.azure.com:443/workflows/fd8c785cd982407a911bec6053990dfb/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=-LfJ6vuZP6Y0_wBnhemuSg6GTsWP0sh9pICRs4PVMV4";
  const { data, status, isSuccess, refetch, error } = useQuery<EventItemType[]>(
    [],
    async () => {
      const requestOptions = {
        method: "GET",
      };
      const res = await fetch(url, requestOptions);
      if (res.status >= 400) {
        throw new Error(res.statusText);
      } else {
        return res.json();
      }
    },
    {
      refetchOnWindowFocus: false,
      retry: false,

      //enabled: false, // turned off by default, manual refetch is needed
    }
  );

  const { languageCode } = useParams();

  const hideOnSmallDevice = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const { t } = useTranslation();

  return (
    <>
      {/*       <Text fontSize={{ base: 18, md: 20 }}>
        {languageCode == "de" && (
          <React.Fragment>
            Auf unserer Veranstaltungsseite informieren wir Sie regelmäßig über
            anstehende Events, Seminare und Workshops rund um das Thema Recht.
            Diese Veranstaltungen bieten sowohl Mandanten als auch
            Interessierten die Möglichkeit, sich über aktuelle rechtliche Themen
            zu informieren und von unserem Expertenwissen zu profitieren.
            Verpassen Sie nicht die Gelegenheit, sich mit unseren Anwälten
            auszutauschen und von ihrem Fachwissen zu lernen.
          </React.Fragment>
        )}
        {languageCode == "en" && (
          <React.Fragment>
            On our events page, we regularly inform you about upcoming events,
            seminars and workshops on the topic of law. These events offer both
            clients and interested parties the opportunity to learn about
            current legal topics and benefit from our expert knowledge. Do not
            miss the opportunity to exchange ideas with our attorneys and learn
            from their expertise.
          </React.Fragment>
        )}
      </Text> */}

      {/*       <Heading mb={2} fontSize={{ base: "18", sm: "20px", md: "25px" }}>
          {t("upcoming-events")}
      </Heading>

      <EventTable
        hideOnSmallDevice={hideOnSmallDevice}
        status={status}
        error={error}
        data={
          data &&
          data
            .filter((x) => DateTime.fromISO(x.Date) > DateTime.now())
            .sort((a, b) => (a.Date > b.Date ? -1 : b.Date > a.Date ? 1 : 0))
            .slice(0, takeFirst ? takeFirst : data.length)
        }
        type={"upcoming"}
      /> */}

      <HeadingMedium mb={10}>{t("past-events")}</HeadingMedium>

      <EventTable
        hideOnSmallDevice={hideOnSmallDevice}
        status={status}
        error={error}
        data={
          data &&
          data
            .filter((x) => DateTime.fromISO(x.Date) < DateTime.now())
            .sort((a, b) => (a.Date > b.Date ? -1 : b.Date > a.Date ? 1 : 0))
            .slice(0, takeFirst ? takeFirst : data.length)
        }
        type={"past"}
      />
    </>
  );
}

function EventTable({
  hideOnSmallDevice,
  status,
  error,
  data,
  type,
}: {
  hideOnSmallDevice: boolean | undefined;
  status: string;
  error: unknown;
  data: EventItemType[] | undefined;
  type: "upcoming" | "past";
}) {
  return (
    <Box>
      {status == "loading" ? (
        <LoadingTable />
      ) : status == "error" ? (
        <ShowError errorMessage={JSON.stringify(error)} />
      ) : status == "success" ? (
        <SimpleGrid
          spacing={4}
          templateColumns={{
            base: "",
            md: "repeat(auto-fill, 32%)",
          }}
        >
          {data &&
            data
              .filter((x) => x.EventId !== "")

              .filter((x) =>
                type == "past"
                  ? DateTime.fromISO(x.Date) < DateTime.now()
                  : DateTime.fromISO(x.Date) > DateTime.now()
              )

              .map((eventItem) => (
                <EventCard eventItem={eventItem} key={eventItem.EventId} />
              ))}
        </SimpleGrid>
      ) : (
        ""
      )}
    </Box>
  );
}

function LoadingTable() {
  const hideOnSmallDevice = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  return (
    <>
      <SimpleGrid
        spacing={4}
        templateColumns={{
          base: "",
          md: "repeat(auto-fill, 32%)",
        }}
      >
        <EventCard isLoading={true} />
        <EventCard isLoading={true} />
        <EventCard isLoading={true} />
      </SimpleGrid>
    </>
  );
}
