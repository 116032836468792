import { Button } from "@chakra-ui/button";
import { Text } from "@chakra-ui/layout";
import { Box, Divider, Stack, VStack } from "@chakra-ui/layout";
import { Form, Formik, FormikProps, useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ShowError } from "../../../generic/ShowError";
import {
  BookingItemType,
  BookingRequest,
  EventItemType,
} from "../../../global/types";
import { Card, CardBody, CardFooter } from "@chakra-ui/react";
import { Heading } from "../../../generic/layout/heading";
import { companyColors } from "../../../global/companyColors";
import { ShowOk } from "../../../generic/ShowOk";
import { GetDaysLeft } from "./helper/GetDaysLeft";
import { FormField } from "../../../generic/forms/FormField";
import { ShowWarning } from "../../../generic/ShowWarning";
import countries from "./helper/countries.json";

export function SubmitToEvent({
  eventItem,
  loading,
}: {
  eventItem: EventItemType | undefined;
  loading: boolean;
}) {
  const [submitted, setSubmitted] = useState<
    "notSubmitted" | "booked" | "overbooked" | "error"
  >("notSubmitted");
  const { t } = useTranslation();
  const { languageCode } = useParams();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showExtendedForm, setShowExtendedForm] = useState<boolean>(false);

  useEffect(() => {
    if (eventItem?.EventId == "nordics-in-hamburg-2024-08") {
      setShowExtendedForm(true);
    }
  }, [eventItem?.EventId]);

  const postRequest = async (
    payload: BookingItemType
  ): Promise<BookingItemType> => {
    const response = await fetch(
      "https://prod-11.germanywestcentral.logic.azure.com:443/workflows/df672503b6f64d0a946b577206af8951/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=Cw2938DkBP7ky_AvlVndpXze3sI7Fm_0djX4HEGgM5Q",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }
    );

    const json = await response.json();

    if (!response.ok) {
      setErrorMessage(json.error.message);
      setSubmitted("error");
    } else {
      setErrorMessage("");
      if (json.isOverbooked) {
        setSubmitted("overbooked");
      } else {
        setSubmitted("booked");
      }
    }

    return json;
  };

  return (
    <Box>
      <Card
        maxW="lg"
        backgroundColor={companyColors.blue[200]}
        textColor={companyColors.blue[800]}
      >
        <CardBody>
          <Stack spacing="3">
            <Heading fontSize={22} mb={4} mt={4}>
              {t("event-submit-header")}
            </Heading>

            <Text>{t("event-submit-intro")}</Text>
          </Stack>
        </CardBody>
        <Divider borderColor={companyColors.blue[600]} />

        <CardFooter>
          <VStack w={"100%"}>
            <Formik<BookingRequest>
              initialValues={{
                Firstname: "",
                Lastname: "",
                Email: "",
                Phone: "",
                City: "",
                Country: "",
                Company: "",
                EventId: eventItem?.EventId || "",
                LanguageCode: languageCode?.toUpperCase() || "",
                Canceled: "",
                WaitList: "",
                AttendingWelcomeReception: "",
                AttendingSocialProgram: "",
                AttendingFridayProgram: undefined,
              }}
              enableReinitialize={true}
              validationSchema={Yup.object({
                Email: Yup.string()
                  .email(t("form-validationError-email").toString())
                  .required(
                    t("form-validationError-mandatoryField").toString()
                  ),
                Firstname: Yup.string().required(
                  t("form-validationError-mandatoryField").toString()
                ),
                Lastname: Yup.string().required(
                  t("form-validationError-mandatoryField").toString()
                ),
                Company: Yup.string().required(
                  t("form-validationError-mandatoryField").toString()
                ),
                Country: Yup.string().required(
                  t("form-validationError-mandatoryField").toString()
                ),
                Phone: Yup.string().required(
                  t("form-validationError-mandatoryField").toString()
                ),

                AttendingWelcomeReception: Yup.string().when("foo", {
                  is: (s: string) => showExtendedForm,
                  then: (s) =>
                    s.required(
                      t("form-validationError-mandatoryField").toString()
                    ),
                }),
                AttendingSocialProgram: Yup.string().when("foo", {
                  is: (s: string) => showExtendedForm,
                  then: (s) =>
                    s.required(
                      t("form-validationError-mandatoryField").toString()
                    ),
                }),
                AttendingFridayProgram: Yup.string().when("foo", {
                  is: (s: string) => showExtendedForm,
                  then: (s) =>
                    s.required(
                      t("form-validationError-mandatoryField").toString()
                    ),
                }),
              })}
              onSubmit={async (values, actions) => {
                await postRequest(values);
              }}
            >
              {(props: FormikProps<BookingRequest>) => (
                <Form style={{ width: "100%" }}>
                  <VStack spacing={4} w={"100%"}>
                    <FormField
                      fieldName="Firstname"
                      disabled={
                        props.isSubmitting || submitted != "notSubmitted"
                      }
                    />
                    <FormField
                      fieldName="Lastname"
                      disabled={
                        props.isSubmitting || submitted != "notSubmitted"
                      }
                    />
                    <FormField
                      fieldName="Company"
                      disabled={
                        props.isSubmitting || submitted != "notSubmitted"
                      }
                    />
                    <FormField
                      fieldName="Country"
                      type="Select"
                      data={countries.map((x) => x.name)}
                      disabled={
                        props.isSubmitting || submitted != "notSubmitted"
                      }
                    />
                    <FormField
                      fieldName="Email"
                      disabled={
                        props.isSubmitting || submitted != "notSubmitted"
                      }
                    />
                    <FormField
                      fieldName="Phone"
                      disabled={
                        props.isSubmitting || submitted != "notSubmitted"
                      }
                    />
                    {showExtendedForm && (
                      <>
                        <Divider borderColor={companyColors.blue[600]} />
                        <Box fontSize="14px">
                          {t("form-label-additional-questions")}
                        </Box>
                        <FormField
                          fieldName="AttendingWelcomeReception"
                          layout="Vertical"
                          type="Select"
                          data={["", "Yes", "No"]}
                          disabled={
                            props.isSubmitting || submitted != "notSubmitted"
                          }
                        />
                        <FormField
                          fieldName="AttendingFridayProgram"
                          layout="Vertical"
                          type="Select"
                          data={["", "Yes", "No"]}
                          disabled={
                            props.isSubmitting || submitted != "notSubmitted"
                          }
                        />
                        <FormField
                          fieldName="AttendingSocialProgram"
                          layout="Vertical"
                          type="Select"
                          data={["", "Yes", "No"]}
                          disabled={
                            props.isSubmitting || submitted != "notSubmitted"
                          }
                        />
                        <Divider borderColor={companyColors.blue[600]} />
                        <Box fontSize="14px" textAlign="right">
                          {languageCode == "de" ? (
                            <Box>
                              Mit dem Absenden dieses Formulars bestätigen Sie,
                              dass Sie mit der Erhebung, Verarbeitung und
                              Nutzung Ihrer Daten zum Zwecke der
                              Veranstaltungsorganisation einverstanden sind.
                              Weitere Informationen erhalten Sie in unserer{" "}
                              <a
                                href={`/${languageCode}/privacy-policy`}
                                target="_blank"
                              >
                                {t("privacy-policy")}
                              </a>
                              .
                            </Box>
                          ) : (
                            <Box>
                              By submitting this form, you confirm that you
                              agree to the collection, processing and use of
                              your data for the purpose of the organization of
                              the event. For further information, please see our{" "}
                              <a
                                href={`/${languageCode}/privacy-policy`}
                                target="_blank"
                              >
                                {t("privacy-policy").toLowerCase()}
                              </a>
                              .
                            </Box>
                          )}
                        </Box>
                      </>
                    )}

                    <Button
                      type="submit"
                      isDisabled={
                        props.isSubmitting ||
                        submitted != "notSubmitted" ||
                        loading ||
                        GetDaysLeft(eventItem?.RegistrationDeadline) == 0 ||
                        eventItem?.BookingIsClosed
                      }
                      isLoading={props.isSubmitting || loading}
                      loadingText={t("event-book-button")}
                      alignSelf="flex-end"
                      colorScheme="blue"
                    >
                      {t("event-book-button")}
                    </Button>
                  </VStack>
                </Form>
              )}
            </Formik>
            {submitted == "error" && (
              <ShowError errorMessage={t(errorMessage)} />
            )}
            {submitted == "booked" && (
              <ShowOk okMessage={t("event-requested-and-see-mails")} />
            )}
            {submitted == "overbooked" && (
              <ShowWarning message={t("event-requested-and-overbooked")} />
            )}
          </VStack>
        </CardFooter>
      </Card>
    </Box>
  );
}
