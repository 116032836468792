import { Layout } from "./generic/layout/layout";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import { LandingPage } from "./pages/landing";
import { ImprintComponent } from "./pages/imprint";
import { PrivacyPolicyComponent } from "./pages/privacy-policy";
import { Helmet } from "react-helmet";

import { googleAnalytics } from "./generic/googleAnalytics";
import "./generic/i18n";
import { CookieConsentComponent } from "./generic/cookieConsent";
import { supportedLngs } from "./generic/i18n";
import { PageNotFound } from "./pages/pageNotFound";
import { ContactComponent } from "./pages/contact";
import { ServicesComponent } from "./pages/services";
import { TeamComponent } from "./pages/team";

import { CareerComponent, CareerContent } from "./pages/career";
import React from "react";
import { AnimatePresence } from "framer-motion";
import { CsrComponent } from "./pages/csr";
import ScrollToTop from "./generic/scrollToTop";
import { EventComponent } from "./pages/insights/events";
import { QueryClient, QueryClientProvider } from "react-query";
import { InsightsComponent } from "./pages/insights";

googleAnalytics();

function App() {
  const location = useLocation();
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <Helmet>
        <meta name="thumbnail" content="https://mangal.legal/media/Icon.jpg" />
      </Helmet>
      <ScrollToTop />

      <Routes>
        <Route
          path=":languageCode"
          element={
            <Layout>
              <div>
                <CookieConsentComponent />
                <Outlet />
              </div>
            </Layout>
          }
        >
          <Route path="contact/*" element={<ContactComponent />} />
          <Route path="csr" element={<CsrComponent />} />
          <Route path="career/*" element={<CareerComponent />} />
          <Route path="services/*" element={<ServicesComponent />} />
          <Route path="team/*" element={<TeamComponent />} />
          <Route path="events/*" element={<EventComponent />} />{" "}
          {/* TO BE DELETED */}
          <Route path="insights/*" element={<InsightsComponent />} />
          <Route index element={<LandingPage />} />
        </Route>
        <Route
          path=":languageCode"
          element={
            <Layout>
              <Outlet />
            </Layout>
          }
        >
          <Route path="imprint" element={<ImprintComponent />} />
          <Route path="privacy-policy" element={<PrivacyPolicyComponent />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
        <Route
          index
          element={
            <Navigate
              to={`/${
                supportedLngs.filter((lng) => lng.isDefault)[0].code ||
                supportedLngs[0].code
              }`}
            />
          }
        />
      </Routes>
    </QueryClientProvider>
  );
}

export default App;
