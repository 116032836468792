import React, { Fragment } from "react";
import { Box, Text, VStack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { JSX_TYPES } from "@babel/types";

export function CompanyAddress({
  showFullContact,
}: {
  showFullContact?: boolean | undefined;
}) {
  const { t } = useTranslation();
  return (
    <Fragment>
      <strong>
        MANGAL LEGAL <br />
        Rechtsanwaltsgesellschaft mbH
      </strong>{" "}
      <br /> <br />
      Hohe Bleichen 24-26
      <br />
      20354 Hamburg
      <br />
      {t("Germany")}
      <br />
      <br />
      info@mangal.legal
      <br />
      +49 40 357 00 97 0
    </Fragment>
  );
}
