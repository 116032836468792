import ReactGA from "react-ga4";

export function googleAnalytics() {
  if (localStorage.getItem("useAnalytics") == "1") {
    ReactGA.initialize("G-GZSQHBL7PE");
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
  }
}
