import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  VStack,
  Box,
  HStack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export function ShowError({ errorMessage }: { errorMessage: string }) {
  const { t } = useTranslation();

  return (
    <>
      <Alert status="error">
        <VStack alignItems="start" flexWrap="wrap">
          <HStack>
            <AlertIcon />
            <AlertTitle mr={2}>{t("error")}</AlertTitle>
          </HStack>

          <AlertDescription>{errorMessage}</AlertDescription>
        </VStack>
        {/* <CloseButton position="absolute" right="8px" top="8px" /> */}
      </Alert>
    </>
  );
}
