import { Button, CardBody, CardFooter, Stack } from "@chakra-ui/react";
import { Routes, Route } from "react-router";
import { PageNotFound } from "../pageNotFound";
import { ServicesContent } from "./ServicesContent";
import { ServicesDetails } from "./ServicesDetails";

export function ServicesComponent() {
  return (
    <Routes>
      <Route index element={<ServicesContent />} />
      <Route path=":service" element={<ServicesDetails />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}
