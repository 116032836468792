import { DateTime, Interval } from "luxon";

export function GetDaysLeft(RegistrationDeadline: string | undefined): number {
  if (RegistrationDeadline !== undefined) {
    const now = DateTime.now();
    const later = DateTime.fromISO(RegistrationDeadline);
    const i = Interval.fromDateTimes(now, later);

    if (later < now) {
      return 0;
    } else {
      return Math.floor(i.length("days"));
    }
  } else {
    return 0;
  }
}
