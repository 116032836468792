import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ShowError } from "../../../generic/ShowError";
import { ShowOk } from "../../../generic/ShowOk";
import { ShowLoading } from "../../../generic/ShowLoading";
import { BookingItemType, EventItemType } from "../../../global/types";
import { EventSummary } from "./EventSummary";
import { Box } from "@chakra-ui/layout";
import { BreadcrumbMenu } from "../../../generic/Breadcrumb";

export function EventApprove() {
  let { validationId } = useParams<{ validationId: string }>();

  return (
    <>
      {validationId ? (
        <EventApproveHandler validationId={validationId} />
      ) : (
        <ShowLoading />
      )}
    </>
  );
}

function EventApproveHandler({ validationId }: { validationId: string }) {
  const { t } = useTranslation();

  let url =
    "https://prod-27.germanywestcentral.logic.azure.com:443/workflows/a1c6da9225074e829855797d49a1f9d7/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=Bk_RcxVv1lyMupyFeTFMApDfNm_mM4npkq9tVo2f-qI";
  const { data, status, isSuccess, refetch, error } = useQuery<BookingItemType>(
    ["emailValidation", validationId],
    async () => {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ id: validationId }),
      };
      const res = await fetch(url, requestOptions);
      if (res.status >= 400) {
        throw new Error(res.statusText);
      } else {
        return res.json();
      }
    },
    {
      refetchOnWindowFocus: false,
      retry: false,

      //enabled: false, // turned off by default, manual refetch is needed
    }
  );
  return (
    <>
      <BreadcrumbMenu secondLevel="events" />
      <Box h={10} />
      {data && <EventDetailsHandler eventId={data.EventId} />}
      <Box h={12} />
      {status == "error" && <ShowError errorMessage={JSON.stringify(error)} />}
      {status == "success" && <ShowOk okMessage={t("event-approve-ok")} />}
      {status == "idle" || (status == "loading" && <ShowLoading />)}
    </>
  );
}

export function EventDetailsHandler({ eventId }: { eventId: string }) {
  let url =
    "https://prod-07.germanywestcentral.logic.azure.com:443/workflows/2f4afe1f667c4c4b9d2c1a365c5f58aa/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=oksnDHERVBF64WLtUKqiCwKT-khpwIqfVr28SIA4A-E";
  const { data, status, isSuccess, refetch, error } = useQuery<EventItemType>(
    [],
    async () => {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ id: eventId }),
      };
      const res = await fetch(url, requestOptions);
      if (res.status >= 400) {
        throw new Error(res.statusText);
      } else {
        return res.json();
      }
    },
    {
      refetchOnWindowFocus: false,
      retry: false,

      //enabled: false, // turned off by default, manual refetch is needed
    }
  );
  return (
    <>
      <EventSummary eventItem={data} loading={status == "loading"} />
      {status == "loading" ? (
        <ShowLoading />
      ) : status == "error" ? (
        <ShowError errorMessage={JSON.stringify(error)} />
      ) : status == "success" ? (
        <>{data && <></>}</>
      ) : (
        ""
      )}
    </>
  );
}
