import { Employee } from "../employee-list";
import React from "react";
import imgElizabethHincapieH from ".././media/elizabeth-hincapie-h.png";

export const elizabethHincapieH: Employee = {
  slug: "elizabeth-hincapie",
  displayName: "Elizabeth Hincapié H.",
  displayNameSuffix: "LL.M.",
  title: "lawyer-female",
  /* secondTitle: "Abogada, Spain & Colombia", */
  isExternal: true,
  img: imgElizabethHincapieH,
  languages: ["deu", "eng", "esp"],
};
