import { Box, VStack } from "@chakra-ui/layout";
import { Heading } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Routes, Route } from "react-router";
import { useParams } from "react-router-dom";
import { BannerWithHeading } from "../../../generic/layout/bannerWithHeading";
import { MaxWidthContainer } from "../../../generic/layout/maxWidthContainer";
import { PageNotFound } from "../../pageNotFound";
import { EventApprove } from "./eventApprove";
import { EventCancel } from "./eventCancel";
import { EventDetails } from "./eventDetails";
import { EventList } from "./eventList";

export function EventComponent() {
  return (
    <Routes>
      <Route index element={<EventContent />} />
      <Route
        path="approve/:validationId"
        element={
          <EventContainer>
            <EventApprove />
          </EventContainer>
        }
      />
      <Route
        path="cancel/:validationId"
        element={
          <EventContainer>
            <EventCancel />
          </EventContainer>
        }
      />
      <Route
        path=":eventId"
        element={
          <EventContainer>
            <EventDetails />
          </EventContainer>
        }
      />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

function EventContainer({ children }: { children: JSX.Element }) {
  const { t } = useTranslation();
  let { eventId } = useParams<{ eventId: string }>();
  return (
    <VStack spacing={0}>
      <BannerWithHeading
        bgUrl={eventId == "nordics-in-hamburg-2024-08" ? "" : "/media/bg5.jpg"}
        headingText={t("events")}
      />

      <Box py={8} w={"100%"}>
        <MaxWidthContainer>{children}</MaxWidthContainer>
      </Box>
    </VStack>
  );
}

export function EventContent() {
  return (
    <EventContainer>
      <EventList />
    </EventContainer>
  );
}
