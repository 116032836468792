import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

export function BreadcrumbMenu({ secondLevel }: { secondLevel: string }) {
  const { languageCode } = useParams();
  const { t } = useTranslation();
  return (
    <Breadcrumb>
      <BreadcrumbItem>
        <BreadcrumbLink href={`/${languageCode}`}>
          {t("nav-home")}
        </BreadcrumbLink>
      </BreadcrumbItem>

      <BreadcrumbItem>
        <Link to={`/${languageCode}/${secondLevel}`}>{t(secondLevel)}</Link>
      </BreadcrumbItem>

      {/*     <BreadcrumbItem isCurrentPage>
      <BreadcrumbLink href="#">Details</BreadcrumbLink>
    </BreadcrumbItem> */}
    </Breadcrumb>
  );
}
